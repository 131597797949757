import React, { useEffect, useRef, useState, useContext } from 'react'
import Nav from '../Components/Nav';
import { Link, Route, useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import BreakHeader from '../Components/BreakHeader';


function Dashboard() {
  const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(true)
  const [dataready, setdataready] = useState(false)

  /// use profits to display earnings too and also
  const [profits, setprofits] = useState(0)

  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  function cleanDate(d) {
    var date = new Date(d);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    console.log("Date: " + date.getDate() +
      "/" + (months[date.getMonth() + 1]) +
      "/" + date.getFullYear() +
      " " + date.getHours() +
      ":" + date.getMinutes() +
      ":" + date.getSeconds());
    return `${date.getDate()} ${(months[date.getMonth()])} ${date.getFullYear()}  ${date.getHours()}  ${date.getMinutes()} ${date.getSeconds()}`
  }

  const history = useNavigate();
  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setOpen(!open);
      planduecheck(userdetails)
      console.log(userdetails.totalearnings)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
          setloading(false)

        } else {
          setloggedin(false);
          setOpen(!open);
          history("/");
        }
      });
    }
  }, []);


  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          console.log(doc.data())
          setloggedin(true)
          setOpen(!open);
          planduecheck(doc.data())
          setdataready(true)
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };


  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    const let3 = await history("/");
  };

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const planduecheck = (info) => {
    const d1 = new Date();
    // Loop through currentSubscriptionArray
    if (info.currentSubscriptionArray.legnth != 0) {

      // && info.currentSubscription.dueDate !==null
      var Profits = 0
      info.currentSubscriptionArray.map((data) => {
        console.log(info.email);
        if (d1.getTime() >= data.dueDate) {
          const newBal =
            parseInt(info.balance) +
            parseInt(data.dueAmount);

          let FilteredArray = info.currentSubscriptionArray.filter((a) => { if (a.dueDate != data.dueDate) { return a } });
          console.log(FilteredArray)
          updateUserBalanceandSub(newBal, data.dueAmount, FilteredArray);
          console.log(data.dueDate);
        } else if (d1.getTime() < data.dueDate) {
          var today = new Date();
          var total = data.dueDate - data.dateSubscribed;
          var progress = today - data.dateSubscribed;

          console.log(Math.round(progress / total * 100) + "%");
          // data.dueAmount-data.amount
          const currentprofit = (((progress / total * 100) * (data.dueAmount - data.amount)) / 100) + Profits
          console.log(currentprofit)
          Profits = currentprofit

          console.log(data.dueDate - d1.getTime());
          const planprogress =
            ((data.dueDate - d1.getTime()) /
              data.dueDate) *
            100;
          console.log(planprogress);
          let date = new Date(data.dueDate);
          console.log(date.toString());
        }
      })

      console.log(Profits)
      // intrestprogress(Profits)
      setprofits(Profits)
    } else {
      console.log(info.email);
    }


  };

  const updateUserBalanceandSub = (bal, addedbal, newarray) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = db.collection("users").doc(userids);
    const balanceToUse = parseFloat(userdetails.balance) + parseFloat(bal)
    washingtonRef
      .update({
        balance: bal,
        currentSubscription: null,
        currentSubscriptionArray: newarray,
        totalearnings: 0,
      })
      .then(function () {
        console.log("balance successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
    // fetchuserdata(userids);
  };
  return (
    <div>

      {/* ########## START: LEFT PANEL ########## */}
      <div className="br-logo">
        <a href="?a=account">
          <img className="brand-logo-dark" src="images/rlogo.png" alt="" height={45} srcSet="images/rlogo.png" />
        </a>
      </div>
<Nav/>
      {/* ########## END: LEFT PANEL ########## */}
      {/* ########## START: HEAD PANEL ########## */}
{/* br-header */}
<BreakHeader/>
      {/* ########## END: HEAD PANEL ########## */}
      {/* ########## START: RIGHT PANEL ########## */}
      <div className="br-sideright">
        <ul className="nav nav-tabs sidebar-tabs" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" role="tab" href="#calendar"><svg className="svg-inline--fa fa-calendar fa-w-14 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="calendar" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg>
              <path fill="currentColor" d="M12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm436-44v-36c0-26.5-21.5-48-48-48h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v36c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12z">
              </path>
            </svg>{/* <i class="menu-item-icon fa fa-calendar tx-22"></i> */}</a>
          </li>
        </ul>{/* sidebar-tabs */}
        {/* Tab panes */}
        <div className="tab-content">
          <div className="tab-pane pos-absolute a-0 mg-t-60 contact-scrollbar active ps" id="contacts" role="tabpanel">
            <label className="sidebar-label pd-x-25 mg-t-25">Time &amp; Date</label>
            <div className="pd-x-25">
              <h2 id="brTime" className="br-time">09:28:44 AM</h2>
              <h6 id="brDate" className="br-date">September 07, 2023 THU</h6>
            </div>
            <label className="sidebar-label pd-x-25 mg-t-25"> Calendar</label>
            <div className="datepicker sidebar-datepicker hasDatepicker" id="dp1694075212014">
              <div className="ui-datepicker-inline ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all" style={{ display: 'block' }}>
                <div className="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all"><a className="ui-datepicker-prev ui-corner-all" data-handler="prev" data-event="click" title="Prev"><span className="ui-icon ui-icon-circle-triangle-w">Prev</span></a><a className="ui-datepicker-next ui-corner-all" data-handler="next" data-event="click" title="Next"><span className="ui-icon ui-icon-circle-triangle-e">Next</span></a>
                  <div className="ui-datepicker-title"><span className="ui-datepicker-month">September</span>&nbsp;<span className="ui-datepicker-year">2023</span></div>
                </div>
                <table className="ui-datepicker-calendar">
                  <thead>
                    <tr>
                      <th scope="col" className="ui-datepicker-week-end"><span title="Sunday">Su</span></th>
                      <th scope="col"><span title="Monday">Mo</span></th>
                      <th scope="col"><span title="Tuesday">Tu</span></th>
                      <th scope="col"><span title="Wednesday">We</span></th>
                      <th scope="col"><span title="Thursday">Th</span></th>
                      <th scope="col"><span title="Friday">Fr</span></th>
                      <th scope="col" className="ui-datepicker-week-end"><span title="Saturday">Sa</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className=" ui-datepicker-week-end ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">
                        &nbsp;</td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">&nbsp;</td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">&nbsp;</td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">&nbsp;</td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">&nbsp;</td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">1</a></td>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">2</a></td>
                    </tr>
                    <tr>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">3</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">4</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">5</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">6</a></td>
                      <td className=" ui-datepicker-days-cell-over  ui-datepicker-current-day ui-datepicker-today" data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default ui-state-highlight ui-state-active ui-state-hover" href="#">7</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">8</a></td>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">9</a></td>
                    </tr>
                    <tr>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">10</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">11</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">12</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">13</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">14</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">15</a></td>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">16</a></td>
                    </tr>
                    <tr>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">17</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">18</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">19</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">20</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">21</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">22</a></td>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">23</a></td>
                    </tr>
                    <tr>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">24</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">25</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">26</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">27</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">28</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">29</a></td>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">30</a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="ps__rail-x" style={{ left: '0px', top: '0px' }}>
              <div className="ps__thumb-x" tabIndex={0} style={{ left: '0px', width: '0px' }} />
            </div>
            <div className="ps__rail-y" style={{ top: '0px', right: '0px' }}>
              <div className="ps__thumb-y" tabIndex={0} style={{ top: '0px', height: '0px' }} />
            </div>
          </div>{/* #contacts */}
          <div className="tab-pane pos-absolute a-0 mg-t-60 attachment-scrollbar ps" id="attachments" role="tabpanel">
            <label className="sidebar-label pd-x-25 mg-t-25">Recent Attachments</label>
            <div className="media-file-list">
              <div className="media">
                <div className="pd-10 bg-gray-500 bg-mojito wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg className="svg-inline--fa fa-file-image fa-w-12 tx-28 tx-white" aria-hidden="true" data-prefix="far" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg>
                    <path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm32-48h224V288l-23.5-23.5c-4.7-4.7-12.3-4.7-17 0L176 352l-39.5-39.5c-4.7-4.7-12.3-4.7-17 0L80 352v64zm48-240c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z">
                    </path>
                  </svg>{/* <i class="far fa-file-image tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">IMG_43445</p>
                  <p className="mg-b-0 tx-12 op-5">JPG Image</p>
                  <p className="mg-b-0 tx-12 op-5">1.2mb</p>
                </div>{/* media-body */}
                <a href="#" className="more"><i className="icon ion-android-more-vertical tx-18" /></a>
              </div>{/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-purple wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg className="svg-inline--fa fa-file-video fa-w-12 tx-28 tx-white" aria-hidden="true" data-prefix="far" data-icon="file-video" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg>
                    <path fill="currentColor" d="M369.941 97.941l-83.882-83.882A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v416c0 26.51 21.49 48 48 48h288c26.51 0 48-21.49 48-48V131.882a48 48 0 0 0-14.059-33.941zM332.118 128H256V51.882L332.118 128zM48 464V48h160v104c0 13.255 10.745 24 24 24h104v288H48zm228.687-211.303L224 305.374V268c0-11.046-8.954-20-20-20H100c-11.046 0-20 8.954-20 20v104c0 11.046 8.954 20 20 20h104c11.046 0 20-8.954 20-20v-37.374l52.687 52.674C286.704 397.318 304 390.28 304 375.986V264.011c0-14.311-17.309-21.319-27.313-11.314z">
                    </path>
                  </svg>{/* <i class="far fa-file-video tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">VID_6543</p>
                  <p className="mg-b-0 tx-12 op-5">MP4 Video</p>
                  <p className="mg-b-0 tx-12 op-5">24.8mb</p>
                </div>{/* media-body */}
                <a href="#" className="more"><i className="icon ion-android-more-vertical tx-18" /></a>
              </div>{/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-reef wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg className="svg-inline--fa fa-file-word fa-w-12 tx-28 tx-white" aria-hidden="true" data-prefix="far" data-icon="file-word" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg>
                    <path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm220.1-208c-5.7 0-10.6 4-11.7 9.5-20.6 97.7-20.4 95.4-21 103.5-.2-1.2-.4-2.6-.7-4.3-.8-5.1.3.2-23.6-99.5-1.3-5.4-6.1-9.2-11.7-9.2h-13.3c-5.5 0-10.3 3.8-11.7 9.1-24.4 99-24 96.2-24.8 103.7-.1-1.1-.2-2.5-.5-4.2-.7-5.2-14.1-73.3-19.1-99-1.1-5.6-6-9.7-11.8-9.7h-16.8c-7.8 0-13.5 7.3-11.7 14.8 8 32.6 26.7 109.5 33.2 136 1.3 5.4 6.1 9.1 11.7 9.1h25.2c5.5 0 10.3-3.7 11.6-9.1l17.9-71.4c1.5-6.2 2.5-12 3-17.3l2.9 17.3c.1.4 12.6 50.5 17.9 71.4 1.3 5.3 6.1 9.1 11.6 9.1h24.7c5.5 0 10.3-3.7 11.6-9.1 20.8-81.9 30.2-119 34.5-136 1.9-7.6-3.8-14.9-11.6-14.9h-15.8z">
                    </path>
                  </svg>{/* <i class="far fa-file-word tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">Tax_Form</p>
                  <p className="mg-b-0 tx-12 op-5">Word Document</p>
                  <p className="mg-b-0 tx-12 op-5">5.5mb</p>
                </div>{/* media-body */}
                <a href="#" className="more"><i className="icon ion-android-more-vertical tx-18" /></a>
              </div>{/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-firewatch wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg className="svg-inline--fa fa-file-pdf fa-w-12 tx-28 tx-white" aria-hidden="true" data-prefix="far" data-icon="file-pdf" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg>
                    <path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z">
                    </path>
                  </svg>{/* <i class="far fa-file-pdf tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">Getting_Started</p>
                  <p className="mg-b-0 tx-12 op-5">PDF Document</p>
                  <p className="mg-b-0 tx-12 op-5">12.7mb</p>
                </div>{/* media-body */}
                <a href="#" className="more"><i className="icon ion-android-more-vertical tx-18" /></a>
              </div>{/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-firewatch wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg className="svg-inline--fa fa-file-pdf fa-w-12 tx-28 tx-white" aria-hidden="true" data-prefix="far" data-icon="file-pdf" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg>
                    <path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z">
                    </path>
                  </svg>{/* <i class="far fa-file-pdf tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">Introduction</p>
                  <p className="mg-b-0 tx-12 op-5">PDF Document</p>
                  <p className="mg-b-0 tx-12 op-5">7.7mb</p>
                </div>{/* media-body */}
                <a href="#" className="more"><i className="icon ion-android-more-vertical tx-18" /></a>
              </div>{/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-mojito wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg className="svg-inline--fa fa-file-image fa-w-12 tx-28 tx-white" aria-hidden="true" data-prefix="far" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg>
                    <path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm32-48h224V288l-23.5-23.5c-4.7-4.7-12.3-4.7-17 0L176 352l-39.5-39.5c-4.7-4.7-12.3-4.7-17 0L80 352v64zm48-240c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z">
                    </path>
                  </svg>{/* <i class="far fa-file-image tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">IMG_43420</p>
                  <p className="mg-b-0 tx-12 op-5">JPG Image</p>
                  <p className="mg-b-0 tx-12 op-5">2.2mb</p>
                </div>{/* media-body */}
                <a href="#" className="more"><i className="icon ion-android-more-vertical tx-18" /></a>
              </div>{/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-mojito wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg className="svg-inline--fa fa-file-image fa-w-12 tx-28 tx-white" aria-hidden="true" data-prefix="far" data-icon="file-image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg>
                    <path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm32-48h224V288l-23.5-23.5c-4.7-4.7-12.3-4.7-17 0L176 352l-39.5-39.5c-4.7-4.7-12.3-4.7-17 0L80 352v64zm48-240c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z">
                    </path>
                  </svg>{/* <i class="far fa-file-image tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">IMG_43447</p>
                  <p className="mg-b-0 tx-12 op-5">JPG Image</p>
                  <p className="mg-b-0 tx-12 op-5">3.2mb</p>
                </div>{/* media-body */}
                <a href="#" className="more"><i className="icon ion-android-more-vertical tx-18" /></a>
              </div>{/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-purple wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg className="svg-inline--fa fa-file-video fa-w-12 tx-28 tx-white" aria-hidden="true" data-prefix="far" data-icon="file-video" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg>
                    <path fill="currentColor" d="M369.941 97.941l-83.882-83.882A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v416c0 26.51 21.49 48 48 48h288c26.51 0 48-21.49 48-48V131.882a48 48 0 0 0-14.059-33.941zM332.118 128H256V51.882L332.118 128zM48 464V48h160v104c0 13.255 10.745 24 24 24h104v288H48zm228.687-211.303L224 305.374V268c0-11.046-8.954-20-20-20H100c-11.046 0-20 8.954-20 20v104c0 11.046 8.954 20 20 20h104c11.046 0 20-8.954 20-20v-37.374l52.687 52.674C286.704 397.318 304 390.28 304 375.986V264.011c0-14.311-17.309-21.319-27.313-11.314z">
                    </path>
                  </svg>{/* <i class="far fa-file-video tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">VID_6545</p>
                  <p className="mg-b-0 tx-12 op-5">AVI Video</p>
                  <p className="mg-b-0 tx-12 op-5">14.8mb</p>
                </div>{/* media-body */}
                <a href="#" className="more"><i className="icon ion-android-more-vertical tx-18" /></a>
              </div>{/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-reef wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg className="svg-inline--fa fa-file-word fa-w-12 tx-28 tx-white" aria-hidden="true" data-prefix="far" data-icon="file-word" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg>
                    <path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm220.1-208c-5.7 0-10.6 4-11.7 9.5-20.6 97.7-20.4 95.4-21 103.5-.2-1.2-.4-2.6-.7-4.3-.8-5.1.3.2-23.6-99.5-1.3-5.4-6.1-9.2-11.7-9.2h-13.3c-5.5 0-10.3 3.8-11.7 9.1-24.4 99-24 96.2-24.8 103.7-.1-1.1-.2-2.5-.5-4.2-.7-5.2-14.1-73.3-19.1-99-1.1-5.6-6-9.7-11.8-9.7h-16.8c-7.8 0-13.5 7.3-11.7 14.8 8 32.6 26.7 109.5 33.2 136 1.3 5.4 6.1 9.1 11.7 9.1h25.2c5.5 0 10.3-3.7 11.6-9.1l17.9-71.4c1.5-6.2 2.5-12 3-17.3l2.9 17.3c.1.4 12.6 50.5 17.9 71.4 1.3 5.3 6.1 9.1 11.6 9.1h24.7c5.5 0 10.3-3.7 11.6-9.1 20.8-81.9 30.2-119 34.5-136 1.9-7.6-3.8-14.9-11.6-14.9h-15.8z">
                    </path>
                  </svg>{/* <i class="far fa-file-word tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">Secret_Document</p>
                  <p className="mg-b-0 tx-12 op-5">Word Document</p>
                  <p className="mg-b-0 tx-12 op-5">4.5mb</p>
                </div>{/* media-body */}
                <a href="#" className="more"><i className="icon ion-android-more-vertical tx-18" /></a>
              </div>{/* media */}
            </div>{/* media-list */}
            <div className="ps__rail-x" style={{ left: '0px', top: '0px' }}>
              <div className="ps__thumb-x" tabIndex={0} style={{ left: '0px', width: '0px' }} />
            </div>
            <div className="ps__rail-y" style={{ top: '0px', right: '0px' }}>
              <div className="ps__thumb-y" tabIndex={0} style={{ top: '0px', height: '0px' }} />
            </div>
          </div>{/* #history */}
          <div className="tab-pane pos-absolute a-0 mg-t-60 schedule-scrollbar ps" id="calendar" role="tabpanel">
            <label className="sidebar-label pd-x-25 mg-t-25">Time &amp; Date</label>
            <div className="pd-x-25">
              <h2 id="brTime" className="br-time" />
              <h6 id="brDate" className="br-date" />
            </div>
            <label className="sidebar-label pd-x-25 mg-t-25">Events Calendar</label>
            <div className="datepicker sidebar-datepicker hasDatepicker" id="dp1694075212015">
              <div className="ui-datepicker-inline ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all" style={{ display: 'block' }}>
                <div className="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all"><a className="ui-datepicker-prev ui-corner-all" data-handler="prev" data-event="click" title="Prev"><span className="ui-icon ui-icon-circle-triangle-w">Prev</span></a><a className="ui-datepicker-next ui-corner-all" data-handler="next" data-event="click" title="Next"><span className="ui-icon ui-icon-circle-triangle-e">Next</span></a>
                  <div className="ui-datepicker-title"><span className="ui-datepicker-month">September</span>&nbsp;<span className="ui-datepicker-year">2023</span></div>
                </div>
                <table className="ui-datepicker-calendar">
                  <thead>
                    <tr>
                      <th scope="col" className="ui-datepicker-week-end"><span title="Sunday">Su</span></th>
                      <th scope="col"><span title="Monday">Mo</span></th>
                      <th scope="col"><span title="Tuesday">Tu</span></th>
                      <th scope="col"><span title="Wednesday">We</span></th>
                      <th scope="col"><span title="Thursday">Th</span></th>
                      <th scope="col"><span title="Friday">Fr</span></th>
                      <th scope="col" className="ui-datepicker-week-end"><span title="Saturday">Sa</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className=" ui-datepicker-week-end ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">
                        &nbsp;</td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">&nbsp;</td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">&nbsp;</td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">&nbsp;</td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">&nbsp;</td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">1</a></td>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">2</a></td>
                    </tr>
                    <tr>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">3</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">4</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">5</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">6</a></td>
                      <td className=" ui-datepicker-days-cell-over  ui-datepicker-current-day ui-datepicker-today" data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default ui-state-highlight ui-state-active ui-state-hover" href="#">7</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">8</a></td>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">9</a></td>
                    </tr>
                    <tr>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">10</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">11</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">12</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">13</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">14</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">15</a></td>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">16</a></td>
                    </tr>
                    <tr>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">17</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">18</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">19</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">20</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">21</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">22</a></td>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">23</a></td>
                    </tr>
                    <tr>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">24</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">25</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">26</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">27</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">28</a></td>
                      <td className=" " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">29</a></td>
                      <td className=" ui-datepicker-week-end " data-handler="selectDay" data-event="click" data-month={8} data-year={2023}><a className="ui-state-default" href="#">30</a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <label className="sidebar-label pd-x-25 mg-t-25">Event Today</label>
            <div className="pd-x-25">
              <div className="list-group sidebar-event-list mg-b-20">
                <div className="list-group-item">
                  <div>
                    <h6>Roven's 32th Birthday</h6>
                    <p>2:30PM</p>
                  </div>
                  <a href="#" className="more"><i className="icon ion-android-more-vertical tx-18" /></a>
                </div>{/* list-group-item */}
                <div className="list-group-item">
                  <div>
                    <h6>Regular Workout Schedule</h6>
                    <p>7:30PM</p>
                  </div>
                  <a href="#" className="more"><i className="icon ion-android-more-vertical tx-18" /></a>
                </div>{/* list-group-item */}
              </div>{/* list-group */}
              <a href="#" className="btn btn-block btn-outline-secondary tx-uppercase tx-12 tx-spacing-2">+ Add Event</a>
              <br />
            </div>
            <div className="ps__rail-x" style={{ left: '0px', top: '0px' }}>
              <div className="ps__thumb-x" tabIndex={0} style={{ left: '0px', width: '0px' }} />
            </div>
            <div className="ps__rail-y" style={{ top: '0px', right: '0px' }}>
              <div className="ps__thumb-y" tabIndex={0} style={{ top: '0px', height: '0px' }} />
            </div>
          </div>
          <div className="tab-pane pos-absolute a-0 mg-t-60 settings-scrollbar ps" id="settings" role="tabpanel">
            <label className="sidebar-label pd-x-25 mg-t-25">Quick Settings</label>
            <div className="sidebar-settings-item">
              <h6 className="tx-13 tx-normal">Sound Notification</h6>
              <p className="op-5 tx-13">Play an alert sound everytime there is a new notification.</p>
              <div className="br-switchbutton checked">
                <input type="hidden" name="switch1" defaultValue="true" />
                <span />
              </div>{/* br-switchbutton */}
            </div>
            <div className="sidebar-settings-item">
              <h6 className="tx-13 tx-normal">2 Steps Verification</h6>
              <p className="op-5 tx-13">Sign in using a two step verification by sending a verification code to your phone.</p>
              <div className="br-switchbutton">
                <input type="hidden" name="switch2" defaultValue="false" />
                <span />
              </div>{/* br-switchbutton */}
            </div>
            <div className="sidebar-settings-item">
              <h6 className="tx-13 tx-normal">Location Services</h6>
              <p className="op-5 tx-13">Allowing us to access your location</p>
              <div className="br-switchbutton">
                <input type="hidden" name="switch3" defaultValue="false" />
                <span />
              </div>{/* br-switchbutton */}
            </div>
            <div className="sidebar-settings-item">
              <h6 className="tx-13 tx-normal">Newsletter Subscription</h6>
              <p className="op-5 tx-13">Enables you to send us news and updates send straight to your email.</p>
              <div className="br-switchbutton checked">
                <input type="hidden" name="switch4" defaultValue="true" />
                <span />
              </div>{/* br-switchbutton */}
            </div>
            <div className="sidebar-settings-item">
              <h6 className="tx-13 tx-normal">Your email</h6>
              <div className="pos-relative">
                <input type="email" name="email" className="form-control" defaultValue="admin@Tintworldtrades..com" />
              </div>
            </div>
            <div className="pd-y-20 pd-x-25">
              <h6 className="tx-13 tx-normal tx-white mg-b-20">More Settings</h6>
              <a href="#" className="btn btn-block btn-outline-secondary tx-uppercase tx-11 tx-spacing-2">Account Settings</a>
              <a href="#" className="btn btn-block btn-outline-secondary tx-uppercase tx-11 tx-spacing-2">Privacy Settings</a>
            </div>
            <div className="ps__rail-x" style={{ left: '0px', top: '0px' }}>
              <div className="ps__thumb-x" tabIndex={0} style={{ left: '0px', width: '0px' }} />
            </div>
            <div className="ps__rail-y" style={{ top: '0px', right: '0px' }}>
              <div className="ps__thumb-y" tabIndex={0} style={{ top: '0px', height: '0px' }} />
            </div>
          </div>
        </div>{/* tab-content */}
      </div>{/* br-sideright */}
      {/* ########## END: RIGHT PANEL ########## -*/}
      {/* ########## START: MAIN PANEL ########## */}
      <div className="br-mainpanel" style={{ position: 'relative' }}>
        <div className="br-pagetitle">
          <svg className="svg-inline--fa fa-home fa-w-18 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="home" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg>
            <path fill="currentColor" d="M488 312.7V456c0 13.3-10.7 24-24 24H348c-6.6 0-12-5.4-12-12V356c0-6.6-5.4-12-12-12h-72c-6.6 0-12 5.4-12 12v112c0 6.6-5.4 12-12 12H112c-13.3 0-24-10.7-24-24V312.7c0-3.6 1.6-7 4.4-9.3l188-154.8c4.4-3.6 10.8-3.6 15.3 0l188 154.8c2.7 2.3 4.3 5.7 4.3 9.3zm83.6-60.9L488 182.9V44.4c0-6.6-5.4-12-12-12h-56c-6.6 0-12 5.4-12 12V117l-89.5-73.7c-17.7-14.6-43.3-14.6-61 0L4.4 251.8c-5.1 4.2-5.8 11.8-1.6 16.9l25.5 31c4.2 5.1 11.8 5.8 16.9 1.6l235.2-193.7c4.4-3.6 10.8-3.6 15.3 0l235.2 193.7c5.1 4.2 12.7 3.5 16.9-1.6l25.5-31c4.2-5.2 3.4-12.7-1.7-16.9z">
            </path>
          </svg>{/* <i class="menu-item-icon fa fa-home tx-22"></i> */}
          <div>
            <h4>Dashboard</h4>
            <p className="mg-b-0">Do bigger things with Tintworldtrades..com.</p>
          </div>
        </div>
        <div className="br-pagebody">
          <div className="row row-sm">
            <div className="col-sm-6 col-xl-3">
              <div className="bg-info rounded overflow-hidden">
                <div className="pd-x-20 pd-t-20 d-flex align-items-center">
                  <svg className="svg-inline--fa fa-chart-area fa-w-16 tx-60 lh-0 tx-white op-7" aria-hidden="true" data-prefix="fas" data-icon="chart-area" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                    <path fill="currentColor" d="M500 384c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v308h436zM372.7 159.5L288 216l-85.3-113.7c-5.1-6.8-15.5-6.3-19.9 1L96 248v104h384l-89.9-187.8c-3.2-6.5-11.4-8.7-17.4-4.7z">
                    </path>
                  </svg>{/* <i class="fas fa-chart-area tx-60 lh-0 tx-white op-7"></i> */}
                  <div className="mg-l-20">
                    <p className="tx-10 tx-spacing-1 tx-mont tx-semibold tx-uppercase tx-white-8 mg-b-10">Total Deposit</p>
                    <p className="tx-24 tx-white tx-lato tx-bold mg-b-0 lh-1"><small>$</small>{userdetails.Totaldeposit}</p>
                    <span className="tx-11 tx-roboto tx-white-8">
                      <small>$</small><b>n/a</b> &nbsp; last deposit
                    </span>
                  </div>
                </div>
                <div id="ch1" className="ht-50 tr-y-1 rickshaw_graph"><svg width="345.2" height={50}>
                  <g>
                    <path d="M0,25Q24.93111111111111,19.291666666666664,28.766666666666666,19.374999999999996C34.519999999999996,19.499999999999996,51.78,25.0625,57.53333333333333,26.25S80.54666666666667,30.875,86.3,31.25S109.31333333333333,30.625,115.06666666666666,30S138.07999999999998,24.25,143.83333333333331,25S166.84666666666666,35.625,172.6,37.5S195.61333333333332,43.75,201.36666666666665,43.75S224.38,38.4375,230.13333333333333,37.5S253.14666666666665,35.3125,258.9,34.375S281.9133333333333,27.8125,287.66666666666663,28.125S310.68,37.8125,316.43333333333334,37.5Q320.2688888888889,37.291666666666664,345.2,25L345.2,50Q320.2688888888889,50,316.43333333333334,50C310.68,50,293.41999999999996,50,287.66666666666663,50S264.6533333333333,50,258.9,50S235.88666666666666,50,230.13333333333333,50S207.11999999999998,50,201.36666666666665,50S178.35333333333332,50,172.6,50S149.58666666666664,50,143.83333333333331,50S120.82,50,115.06666666666666,50S92.05333333333333,50,86.3,50S63.28666666666666,50,57.53333333333333,50S34.519999999999996,50,28.766666666666666,50Q24.93111111111111,50,0,50Z" className="area" fill="rgba(255,255,255,0.5)" />
                  </g>
                </svg></div>
              </div>
            </div>{/* col-3 */}
            <div className="col-sm-6 col-xl-3 mg-t-20 mg-sm-t-0">
              <div className="bg-purple rounded overflow-hidden">
                <div className="pd-x-20 pd-t-20 d-flex align-items-center">
                  <svg className="svg-inline--fa fa-chart-area fa-w-16 tx-56 lh-0 tx-white op-7" aria-hidden="true" data-prefix="fas" data-icon="chart-area" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                    <path fill="currentColor" d="M500 384c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v308h436zM372.7 159.5L288 216l-85.3-113.7c-5.1-6.8-15.5-6.3-19.9 1L96 248v104h384l-89.9-187.8c-3.2-6.5-11.4-8.7-17.4-4.7z">
                    </path>
                  </svg>{/* <i class="fas fa-chart-area tx-56 lh-0 tx-white op-7"></i> */}
                  <div className="mg-l-20">
                    <p className="tx-10 tx-spacing-1 tx-mont tx-semibold tx-uppercase tx-white-8 mg-b-10">Total Earning</p>
                    <p className="tx-24 tx-white tx-lato tx-bold mg-b-0 lh-1"><small>$</small>{userdetails.balance}</p>
                    <span className="tx-11 tx-roboto tx-white-8">
                      <small>$</small>0.00 pending withdrawal
                    </span>
                  </div>
                </div>
                <div id="ch3" className="ht-50 tr-y-1 rickshaw_graph"><svg width="345.2" height={50}>
                  <g>
                    <path d="M0,25Q24.93111111111111,21.458333333333332,28.766666666666666,21.875C34.519999999999996,22.5,51.78,30.9375,57.53333333333333,31.25S80.54666666666667,26.25,86.3,25S109.31333333333333,18.75,115.06666666666666,18.75S138.07999999999998,23.125,143.83333333333331,25S166.84666666666666,35.625,172.6,37.5S195.61333333333332,43.75,201.36666666666665,43.75S224.38,38.4375,230.13333333333333,37.5S253.14666666666665,35.3125,258.9,34.375S281.9133333333333,27.8125,287.66666666666663,28.125S310.68,37.8125,316.43333333333334,37.5Q320.2688888888889,37.291666666666664,345.2,25L345.2,50Q320.2688888888889,50,316.43333333333334,50C310.68,50,293.41999999999996,50,287.66666666666663,50S264.6533333333333,50,258.9,50S235.88666666666666,50,230.13333333333333,50S207.11999999999998,50,201.36666666666665,50S178.35333333333332,50,172.6,50S149.58666666666664,50,143.83333333333331,50S120.82,50,115.06666666666666,50S92.05333333333333,50,86.3,50S63.28666666666666,50,57.53333333333333,50S34.519999999999996,50,28.766666666666666,50Q24.93111111111111,50,0,50Z" className="area" fill="rgba(255,255,255,0.5)" />
                  </g>
                </svg></div>
              </div>


              <div className="bg-purple rounded overflow-hidden">
                <div className="pd-x-20 pd-t-20 d-flex align-items-center">
                  <svg className="svg-inline--fa fa-chart-area fa-w-16 tx-56 lh-0 tx-white op-7" aria-hidden="true" data-prefix="fas" data-icon="chart-area" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                    <path fill="currentColor" d="M500 384c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v308h436zM372.7 159.5L288 216l-85.3-113.7c-5.1-6.8-15.5-6.3-19.9 1L96 248v104h384l-89.9-187.8c-3.2-6.5-11.4-8.7-17.4-4.7z">
                    </path>
                  </svg>{/* <i class="fas fa-chart-area tx-56 lh-0 tx-white op-7"></i> */}
                  <div className="mg-l-20">
                    <p className="tx-10 tx-spacing-1 tx-mont tx-semibold tx-uppercase tx-white-8 mg-b-10">Current Earnings</p>
                    <p className="tx-24 tx-white tx-lato tx-bold mg-b-0 lh-1"><small>$</small>{profits}</p>
                    <span className="tx-11 tx-roboto tx-white-8">
                      <small>$</small>0.00 pending withdrawal
                    </span>
                  </div>
                </div>
                <div id="ch3" className="ht-50 tr-y-1 rickshaw_graph"><svg width="345.2" height={50}>
                  <g>
                    <path d="M0,25Q24.93111111111111,21.458333333333332,28.766666666666666,21.875C34.519999999999996,22.5,51.78,30.9375,57.53333333333333,31.25S80.54666666666667,26.25,86.3,25S109.31333333333333,18.75,115.06666666666666,18.75S138.07999999999998,23.125,143.83333333333331,25S166.84666666666666,35.625,172.6,37.5S195.61333333333332,43.75,201.36666666666665,43.75S224.38,38.4375,230.13333333333333,37.5S253.14666666666665,35.3125,258.9,34.375S281.9133333333333,27.8125,287.66666666666663,28.125S310.68,37.8125,316.43333333333334,37.5Q320.2688888888889,37.291666666666664,345.2,25L345.2,50Q320.2688888888889,50,316.43333333333334,50C310.68,50,293.41999999999996,50,287.66666666666663,50S264.6533333333333,50,258.9,50S235.88666666666666,50,230.13333333333333,50S207.11999999999998,50,201.36666666666665,50S178.35333333333332,50,172.6,50S149.58666666666664,50,143.83333333333331,50S120.82,50,115.06666666666666,50S92.05333333333333,50,86.3,50S63.28666666666666,50,57.53333333333333,50S34.519999999999996,50,28.766666666666666,50Q24.93111111111111,50,0,50Z" className="area" fill="rgba(255,255,255,0.5)" />
                  </g>
                </svg></div>
              </div>

              
            </div>{/* col-3 */}
            <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
              <div className="bg-teal rounded overflow-hidden">
                <div className="pd-x-20 pd-t-20 d-flex align-items-center">
                  <svg className="svg-inline--fa fa-chart-area fa-w-16 tx-60 lh-0 tx-white op-7" aria-hidden="true" data-prefix="fas" data-icon="chart-area" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                    <path fill="currentColor" d="M500 384c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v308h436zM372.7 159.5L288 216l-85.3-113.7c-5.1-6.8-15.5-6.3-19.9 1L96 248v104h384l-89.9-187.8c-3.2-6.5-11.4-8.7-17.4-4.7z">
                    </path>
                  </svg>{/* <i class="fas fa-chart-area tx-60 lh-0 tx-white op-7"></i> */}
                  <div className="mg-l-20">
                    <p className="tx-10 tx-spacing-1 tx-mont tx-semibold tx-uppercase tx-white-8 mg-b-10">Withdrawal</p>
                    <p className="tx-24 tx-white tx-lato tx-bold mg-b-0 lh-1"><small>$</small>{userdetails.Totalwithdrawal}</p>
                    <span className="tx-11 tx-roboto tx-white-8">
                      <small>$</small>n/a last withdrawal
                    </span>
                  </div>
                </div>
                <div id="ch2" className="ht-50 tr-y-1 rickshaw_graph"><svg width="345.2" height={50}>
                  <g>
                    <path d="M0,25Q24.93111111111111,40.541666666666664,28.766666666666666,40.625C34.519999999999996,40.75,51.78,27.8125,57.53333333333333,26.25S80.54666666666667,24.625,86.3,25S109.31333333333333,30.625,115.06666666666666,30S138.07999999999998,20.8125,143.83333333333331,18.75S166.84666666666666,10.625,172.6,9.375S195.61333333333332,5,201.36666666666665,6.25S224.38,20.9375,230.13333333333333,21.875S253.14666666666665,16.5625,258.9,15.625S281.9133333333333,12.1875,287.66666666666663,12.5S310.68,17.5,316.43333333333334,18.75Q320.2688888888889,19.583333333333332,345.2,25L345.2,50Q320.2688888888889,50,316.43333333333334,50C310.68,50,293.41999999999996,50,287.66666666666663,50S264.6533333333333,50,258.9,50S235.88666666666666,50,230.13333333333333,50S207.11999999999998,50,201.36666666666665,50S178.35333333333332,50,172.6,50S149.58666666666664,50,143.83333333333331,50S120.82,50,115.06666666666666,50S92.05333333333333,50,86.3,50S63.28666666666666,50,57.53333333333333,50S34.519999999999996,50,28.766666666666666,50Q24.93111111111111,50,0,50Z" className="area" fill="rgba(255,255,255,0.5)" />
                  </g>
                </svg></div>
              </div>
            </div>{/* col-3 */}
            <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
              <div className="bg-primary rounded overflow-hidden">
                <div className="pd-x-20 pd-t-20 d-flex align-items-center">
                  <svg className="svg-inline--fa fa-chart-area fa-w-16 tx-60 lh-0 tx-white op-7" aria-hidden="true" data-prefix="fas" data-icon="chart-area" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                    <path fill="currentColor" d="M500 384c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v308h436zM372.7 159.5L288 216l-85.3-113.7c-5.1-6.8-15.5-6.3-19.9 1L96 248v104h384l-89.9-187.8c-3.2-6.5-11.4-8.7-17.4-4.7z">
                    </path>
                  </svg>{/* <i class="fas fa-chart-area tx-60 lh-0 tx-white op-7"></i> */}
                  <div className="mg-l-20">
                    <p className="tx-10 tx-spacing-1 tx-mont tx-semibold tx-uppercase tx-white-8 mg-b-10">Available to Deal</p>
                    <p className="tx-24 tx-white tx-lato tx-bold mg-b-0 lh-1"><small>$</small>0.00</p>
                    <span className="tx-11 tx-roboto tx-white-8">
                      $ referral bonus
                    </span>
                  </div>
                </div>
                <div id="ch4" className="ht-50 tr-y-1 rickshaw_graph"><svg width="345.2" height={50}>
                  <g>
                    <path d="M0,25Q24.93111111111111,21.458333333333332,28.766666666666666,21.875C34.519999999999996,22.5,51.78,30.9375,57.53333333333333,31.25S80.54666666666667,26.25,86.3,25S109.31333333333333,18.75,115.06666666666666,18.75S138.07999999999998,23.125,143.83333333333331,25S166.84666666666666,35.625,172.6,37.5S195.61333333333332,43.75,201.36666666666665,43.75S224.38,38.4375,230.13333333333333,37.5S253.14666666666665,35.3125,258.9,34.375S281.9133333333333,27.8125,287.66666666666663,28.125S310.68,37.8125,316.43333333333334,37.5Q320.2688888888889,37.291666666666664,345.2,25L345.2,50Q320.2688888888889,50,316.43333333333334,50C310.68,50,293.41999999999996,50,287.66666666666663,50S264.6533333333333,50,258.9,50S235.88666666666666,50,230.13333333333333,50S207.11999999999998,50,201.36666666666665,50S178.35333333333332,50,172.6,50S149.58666666666664,50,143.83333333333331,50S120.82,50,115.06666666666666,50S92.05333333333333,50,86.3,50S63.28666666666666,50,57.53333333333333,50S34.519999999999996,50,28.766666666666666,50Q24.93111111111111,50,0,50Z" className="area" fill="rgba(255,255,255,0.5)" />
                  </g>
                </svg></div>
              </div>
            </div>{/* col-3 */}
          </div>
          {/* row */}
          <div className="row no-gutters shadow-base mg-b-10" style={{ marginTop: '10px' }}>
            <div className="col-sm-12 col-lg-12">
              <div className="card">
                <div className="card-footer">
                  <div className="ccc-widget ccc-header-v3" id="marquee-container" style={{ background: 'rgb(255, 255, 255)', boxSizing: 'border-box', lineHeight: 1, overflow: 'hidden' }}>
                    <div id="marquee-inner">
                      <div className="ccc-coin-header-v3-container marquee" id="marquee-element" style={{ margin: '0px 0px 0px -1520px', padding: '0px', transition: 'all 1s linear 0s' }}>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/btc/overview/usd" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746251/btc.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">BTC: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_BTC_USD">$
                              25,727.6</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_BTC_USD" style={{ color: '#A11B0A' }}>
                              (<span id="CCCHeader3ChangePctBTC_USD">-0.03 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/btc/overview/eur" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746251/btc.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">BTC: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_BTC_EUR">€
                              24,019.8</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_BTC_EUR" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctBTC_EUR">0.18 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/btc/overview/cny" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746251/btc.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">BTC: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_BTC_CNY">¥
                              172,521.3</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_BTC_CNY" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctBTC_CNY">0.00 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/btc/overview/gbp" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746251/btc.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">BTC: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_BTC_GBP">£
                              20,706.2</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_BTC_GBP" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctBTC_GBP">0.72 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/eth/overview/usd" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746238/eth.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">ETH: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_ETH_USD">$
                              1,631.71</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_ETH_USD" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctETH_USD">0.08 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/eth/overview/eur" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746238/eth.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">ETH: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_ETH_EUR">€
                              1,524.08</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_ETH_EUR" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctETH_EUR">0.29 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/eth/overview/cny" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746238/eth.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">ETH: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_ETH_CNY">¥
                              10,943.0</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_ETH_CNY" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctETH_CNY">0.13 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/eth/overview/gbp" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746238/eth.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">ETH: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_ETH_GBP">£
                              1,311.82</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_ETH_GBP" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctETH_GBP">0.87 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/xmr/overview/usd" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746883/xmr.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">XMR: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_XMR_USD">$
                              141.26</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_XMR_USD" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctXMR_USD">1.34 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/xmr/overview/eur" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746883/xmr.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">XMR: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_XMR_EUR">€
                              131.82</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_XMR_EUR" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctXMR_EUR">1.57 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/xmr/overview/cny" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746883/xmr.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">XMR: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_XMR_CNY">¥
                              947.14</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_XMR_CNY" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctXMR_CNY">1.53 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/xmr/overview/gbp" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746883/xmr.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">XMR: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_XMR_GBP">£
                              113.35</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_XMR_GBP" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctXMR_GBP">2.18 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/ltc/overview/usd" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746243/ltc.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">LTC: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_LTC_USD">$
                              63.08</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_LTC_USD" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctLTC_USD">0.33 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/ltc/overview/eur" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746243/ltc.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">LTC: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_LTC_EUR">€
                              58.86</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_LTC_EUR" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctLTC_EUR">0.54 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/ltc/overview/cny" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746243/ltc.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">LTC: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_LTC_CNY">¥
                              423.19</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_LTC_CNY" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctLTC_CNY">0.33 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/ltc/overview/gbp" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746243/ltc.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">LTC: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_LTC_GBP">£
                              50.61</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_LTC_GBP" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctLTC_GBP">1.15 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/dash/overview/usd" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746893/dash.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">DASH: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_DASH_USD">$
                              25.66</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_DASH_USD" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctDASH_USD">0.87 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/dash/overview/eur" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746893/dash.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">DASH: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_DASH_EUR">€
                              23.93</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_DASH_EUR" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctDASH_EUR">1.02 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/dash/overview/cny" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746893/dash.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">DASH: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_DASH_CNY">¥
                              171.85</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_DASH_CNY" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctDASH_CNY">0.72 %</span>)
                            </span>
                          </a>
                        </div>
                        <div className="ccc-header-v3-ccc-price-container">
                          <a href="https://www.cryptocompare.com/coins/dash/overview/gbp" target="_blank" rel="nofollow">
                            <img className="ccc-header-v3-price-logo" src="https://www.cryptocompare.com/media/37746893/dash.png" style={{ width: '15px', height: '15px', verticalAlign: 'middle' }} />
                            <span className="ccc-header-v3-price-name">DASH: </span>
                            <span className="ccc-header-v3-price-value" style={{ color: '#000' }} id="CCCHeader3Price_DASH_GBP">£
                              20.58</span>
                            <span className="ccc-header-v3-price-change" id="CCCHeader3ChangePctContainer_DASH_GBP" style={{ color: '#3d9400' }}>
                              (<span id="CCCHeader3ChangePctDASH_GBP">1.63 %</span>)
                            </span>
                          </a>
                        </div>
                        <style dangerouslySetInnerHTML={{ __html: "" }} />
                      </div>
                    </div>
                  </div>
                </div>{/* card-footer */}
              </div>{/* card */}
            </div>{/* col-3 */}
          </div>
          <div className="row no-gutters widget-1 shadow-base" style={{ marginTop: '10px' }}>
            <div className="col-sm-6 col-lg-4">
              <div className="card">
                <div className="card-header">
                </div>{/* card-header */}
                <div className="card-body">
                  {/* TradingView Widget BEGIN */}
                  <div className="tradingview-widget-container" style={{ width: '100%', height: '126px' }}>
                    <iframe scrolling="no" allowTransparency="true" frameBorder={0} src="https://www.tradingview-widget.com/embed-widget/single-quote/?locale=en#%7B%22symbol%22%3A%22FX%3AEURUSD%22%2C%22width%22%3A%22100%25%22%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22height%22%3A126%2C%22utm_source%22%3A%22Tintworldtrades..com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22single-quote%22%2C%22page-uri%22%3A%22Tintworldtrades..com%2F%3Fa%3Daccount%22%7D" title="single quote TradingView widget" lang="en" style={{ userSelect: 'none', boxSizing: 'border-box', display: 'block', height: 'calc(94px)', width: '100%' }} />
                    <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/FX-EURUSD/?utm_source=Tintworldtrades..com&utm_medium=widget_new&utm_campaign=single-quote" rel="noopener" target="_blank"><span className="blue-text">EURUSD Rates</span></a> by TradingView</div>
                    <style dangerouslySetInnerHTML={{ __html: "\n                  .tradingview-widget-copyright {\n                    font-size: 13px !important;\n                    line-height: 32px !important;\n                    text-align: center !important;\n                    vertical-align: middle !important;\n                    /* @mixin sf-pro-display-font; */\n                    font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif !important;\n                    color: #9db2bd !important;\n                  }\n\n                  .tradingview-widget-copyright .blue-text {\n                    color: #2962FF !important;\n                  }\n\n                  .tradingview-widget-copyright a {\n                    text-decoration: none !important;\n                    color: #9db2bd !important;\n                  }\n\n                  .tradingview-widget-copyright a:visited {\n                    color: #9db2bd !important;\n                  }\n\n                  .tradingview-widget-copyright a:hover .blue-text {\n                    color: #1E53E5 !important;\n                  }\n\n                  .tradingview-widget-copyright a:active .blue-text {\n                    color: #1848CC !important;\n                  }\n\n                  .tradingview-widget-copyright a:visited .blue-text {\n                    color: #2962FF !important;\n                  }\n                " }} />
                  </div>
                  <span> &nbsp;</span>
                  {/* TradingView Widget END */}
                </div>{/* card-body */}
              </div>{/* card */}
            </div>{/* col-3 */}
            <div className="col-sm-6 col-lg-4">
              <div className="card">
                <div className="card-header">
                </div>{/* card-header */}
                <div className="card-body">
                  {/* TradingView Widget BEGIN */}
                  <div className="tradingview-widget-container" style={{ width: '100%', height: '126px' }}>
                    <iframe scrolling="no" allowTransparency="true" frameBorder={0} src="https://www.tradingview-widget.com/embed-widget/single-quote/?locale=en#%7B%22symbol%22%3A%22COINBASE%3ABTCEUR%22%2C%22width%22%3A%22100%25%22%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Afalse%2C%22height%22%3A126%2C%22utm_source%22%3A%22Tintworldtrades..com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22single-quote%22%2C%22page-uri%22%3A%22Tintworldtrades..com%2F%3Fa%3Daccount%22%7D" title="single quote TradingView widget" lang="en" style={{ userSelect: 'none', boxSizing: 'border-box', display: 'block', height: 'calc(94px)', width: '100%' }} />
                    <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/COINBASE-BTCEUR/?utm_source=Tintworldtrades..com&utm_medium=widget_new&utm_campaign=single-quote" rel="noopener" target="_blank"><span className="blue-text">BTCEUR Rates</span></a> by TradingView</div>
                    <style dangerouslySetInnerHTML={{ __html: "\n                  .tradingview-widget-copyright {\n                    font-size: 13px !important;\n                    line-height: 32px !important;\n                    text-align: center !important;\n                    vertical-align: middle !important;\n                    /* @mixin sf-pro-display-font; */\n                    font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif !important;\n                    color: #9db2bd !important;\n                  }\n\n                  .tradingview-widget-copyright .blue-text {\n                    color: #2962FF !important;\n                  }\n\n                  .tradingview-widget-copyright a {\n                    text-decoration: none !important;\n                    color: #9db2bd !important;\n                  }\n\n                  .tradingview-widget-copyright a:visited {\n                    color: #9db2bd !important;\n                  }\n\n                  .tradingview-widget-copyright a:hover .blue-text {\n                    color: #1E53E5 !important;\n                  }\n\n                  .tradingview-widget-copyright a:active .blue-text {\n                    color: #1848CC !important;\n                  }\n\n                  .tradingview-widget-copyright a:visited .blue-text {\n                    color: #2962FF !important;\n                  }\n                " }} />
                  </div>
                  {/* TradingView Widget END */}
                </div>{/* card-body */}
              </div>{/* card */}
            </div>{/* col-3 */}
            <div className="col-sm-6 col-lg-4">
              <div className="card">
                <div className="card-header">
                </div>{/* card-header */}
                <div className="card-body">
                  {/* TradingView Widget BEGIN */}
                  <div className="tradingview-widget-container" style={{ width: '100%', height: '126px' }}>
                    <iframe scrolling="no" allowTransparency="true" frameBorder={0} src="https://www.tradingview-widget.com/embed-widget/single-quote/?locale=en#%7B%22symbol%22%3A%22AMEX%3AUSO%22%2C%22width%22%3A%22100%25%22%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22height%22%3A126%2C%22utm_source%22%3A%22Tintworldtrades..com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22single-quote%22%2C%22page-uri%22%3A%22Tintworldtrades..com%2F%3Fa%3Daccount%22%7D" title="single quote TradingView widget" lang="en" style={{ userSelect: 'none', boxSizing: 'border-box', display: 'block', height: 'calc(94px)', width: '100%' }} />
                    <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/AMEX-USO/?utm_source=Tintworldtrades..com&utm_medium=widget_new&utm_campaign=single-quote" rel="noopener" target="_blank"><span className="blue-text">USO Quotes</span></a> by TradingView</div>
                    <style dangerouslySetInnerHTML={{ __html: "\n                  .tradingview-widget-copyright {\n                    font-size: 13px !important;\n                    line-height: 32px !important;\n                    text-align: center !important;\n                    vertical-align: middle !important;\n                    /* @mixin sf-pro-display-font; */\n                    font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif !important;\n                    color: #9db2bd !important;\n                  }\n\n                  .tradingview-widget-copyright .blue-text {\n                    color: #2962FF !important;\n                  }\n\n                  .tradingview-widget-copyright a {\n                    text-decoration: none !important;\n                    color: #9db2bd !important;\n                  }\n\n                  .tradingview-widget-copyright a:visited {\n                    color: #9db2bd !important;\n                  }\n\n                  .tradingview-widget-copyright a:hover .blue-text {\n                    color: #1E53E5 !important;\n                  }\n\n                  .tradingview-widget-copyright a:active .blue-text {\n                    color: #1848CC !important;\n                  }\n\n                  .tradingview-widget-copyright a:visited .blue-text {\n                    color: #2962FF !important;\n                  }\n                " }} />
                  </div>
                  {/* TradingView Widget END */}
                </div>{/* card-body */}
              </div>{/* card */}
            </div>{/* col-3 */}
          </div>
          <div className="row row-sm mg-t-20">
            <div className="col-lg-8">
              <div className="card bd-0 shadow-base">
                <div className="d-md-flex justify-content-between pd-25" style={{ paddingBottom: 0 }}>
                  <div>
                    <h6 className="tx-13 tx-uppercase tx-inverse tx-semibold tx-spacing-1">Bitcoin Chart &amp; News</h6>
                  </div>
                </div>{/* d-flex */}
                <div className="pd-l-25 pd-r-15 pd-b-25">
                  <div className>
                    <div className="ccc-widget ccc-feed" style={{ lineHeight: 1 }}>
                      <div style={{ background: 'rgb(255, 255, 255)', borderRadius: '10px 10px 0px 0px', border: '1px solid rgb(224, 230, 239)', boxSizing: 'border-box', paddingBottom: '0px' }}>
                        <div style={{ fontSize: '23px', fontWeight: 700, padding: '10px 10px 0px' }}><a href="https://www.cryptocompare.com/coins/btc/overview" rel="nofollow" title="BTC Price and market cap" target="_blank" style={{ color: '#000', textDecoration: 'none' }}>
                          <span> <img src="https://www.cryptocompare.com/media/37746251/btc.png" style={{ width: '20px', height: '20px', verticalAlign: 'middle', border: 0 }} /> <span style={{ fontSize: '15px' }}>BTC</span> <span style={{ fontSize: '11px', fontWeight: 'bold', display: 'none' }}>BTC</span> $ 25,725.0 </span>
                          <span style={{ fontSize: '13px', fontWeight: 'bold', color: '#A11B0A' }}> (-0.05%) </span></a><a href="https://www.cryptocompare.com/dev/widget/wizard/" rel="nofollow" style={{ float: 'right', fontSize: '10px', fontWeight: 'normal', textDecoration: 'none', color: '#999', marginTop: '0px', textAlign: 'right', lineHeight: 1 }} target="_blank"> powered by <br /><b style={{ color: '#2B5A99' }}>CryptoCompare</b></a></div>
                        <div style={{ overflow: 'hidden', color: 'rgb(1, 1, 1)', paddingTop: '10px', paddingBottom: '10px' }}>
                          <div style={{ float: 'left', fontSize: '11px', fontWeight: 'normal', lineHeight: '11px', marginRight: '0px', paddingLeft: '10px', marginTop: '0px', marginBottom: '5px' }}>
                            <b style={{ color: '#a5a5a5', borderLeft: '3px solid #fdc904', paddingLeft: '5px' }}> Last updated:</b>
                            <span style={{ color: '#000' }}> Just now</span></div>
                          <div style={{ float: 'right', fontSize: '11px', fontWeight: 'normal', lineHeight: '11px', paddingRight: '10px', marginTop: '0px', marginBottom: '5px' }}>
                            <b style={{ color: '#a5a5a5', borderLeft: '3px solid #fdc904', paddingLeft: '5px' }}> USD Vol:</b>
                            <span style={{ color: '#000' }}> $ 387.91 M</span></div>
                          <div style={{ clear: 'both', margin: '0px', padding: '0px', height: '0px', display: 'block', visibility: 'hidden' }}>
                          </div>
                          <div style={{ float: 'left', fontSize: '11px', fontWeight: 'normal', lineHeight: '11px', marginRight: '0px', paddingLeft: '10px', marginTop: '0px', marginBottom: '0px' }}>
                            <b style={{ color: '#a5a5a5', borderLeft: '3px solid #fdc904', paddingLeft: '5px' }}>Mkt Cap:</b> <span style={{ color: '#000' }}> $ 501.09 B</span></div>
                          <div style={{ float: 'right', fontSize: '11px', fontWeight: 'normal', lineHeight: '11px', paddingRight: '10px', marginTop: '0px', marginBottom: '0px' }}>
                            <b style={{ color: '#a5a5a5', borderLeft: '3px solid #fdc904', paddingLeft: '5px' }}>All Vol:</b> <span style={{ color: '#000' }}> $ 1.80 B</span></div>
                        </div>
                        <div><iframe className="chartjs-hidden-iframe" style={{ width: '100%', display: 'block', border: '0px', height: '0px', margin: '0px', position: 'absolute', inset: '0px' }} /><canvas height={162} width={608} style={{ display: 'block', width: '304px', height: '81px' }} /></div>
                        <div style={{ overflowY: 'scroll', height: '300px' }}>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://coinpedia.org/news/sbi-remit-and-ripple-revolutionizing-international-payments-with-xrp/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/coinpedia.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://coinpedia.org/news/sbi-remit-and-ripple-revolutionizing-international-payments-with-xrp/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> SBI Remit and Ripple: Revolutionizing International Payments with XRP
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://thecryptobasic.com/2023/09/07/ripple-chair-slams-sec-says-it-lost-on-everything-important/?utm_source=rss&#038;utm_medium=rss&#038;utm_campaign=ripple-chair-slams-sec-says-it-lost-on-everything-important" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/thecryptobasic.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://thecryptobasic.com/2023/09/07/ripple-chair-slams-sec-says-it-lost-on-everything-important/?utm_source=rss&#038;utm_medium=rss&#038;utm_campaign=ripple-chair-slams-sec-says-it-lost-on-everything-important" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Ripple Chair Slams SEC, Says It “Lost on Everything Important”
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://cryptopotato.com/huge-amount-of-xrp-on-the-move-reasons-to-worry-or-business-as-usual/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/27/17243859.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://cryptopotato.com/huge-amount-of-xrp-on-the-move-reasons-to-worry-or-business-as-usual/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Huge Amount of XRP on the Move: Reasons to Worry or Business as Usual?
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://crypto.news/ethereum-wasm-enhanced-with-stylus-as-chainlink-and-swift-partner-borroe-finance-surges-25/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/73/17243891.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://crypto.news/ethereum-wasm-enhanced-with-stylus-as-chainlink-and-swift-partner-borroe-finance-surges-25/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Ethereum WASM enhanced with Stylus as Chainlink and SWIFT partner, Borroe.Finance surges
                            25%
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://www.cryptopolitan.com/us-companies-tech-innovations-at-ciftis/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/cryptopolitan.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://www.cryptopolitan.com/us-companies-tech-innovations-at-ciftis/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> US Companies Showcase High-Tech Innovations at 2023 CIFTIS
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://en.coinotag.com/us-agencies-outline-monumental-bitcoin-regulations-targeting-brokers/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/77/default.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://en.coinotag.com/us-agencies-outline-monumental-bitcoin-regulations-targeting-brokers/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> US Agencies Outline Monumental Bitcoin Regulations Targeting Brokers
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://www.cryptopolitan.com/privacy-coins-now-delisted-in-belgium/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/cryptopolitan.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://www.cryptopolitan.com/privacy-coins-now-delisted-in-belgium/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Binance continues European clampdown: Privacy coins now delisted in Belgium
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://cryptodaily.co.uk/2023/09/arkham-identifies-grayscale-as-second-largest-bitcoin-holder" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/75/17243586.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://cryptodaily.co.uk/2023/09/arkham-identifies-grayscale-as-second-largest-bitcoin-holder" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Arkham Identifies Grayscale As Second Largest Bitcoin Holder
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://www.cryptoglobe.com/latest/2023/09/ada-a-closer-look-at-cardano-powered-dex-wingriders-launchpad-platform/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/2/17243871.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://www.cryptoglobe.com/latest/2023/09/ada-a-closer-look-at-cardano-powered-dex-wingriders-launchpad-platform/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> $ADA: A Closer Look at Cardano-Powered DEX WingRiders’ Launchpad Platform
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://en.coin-turk.com/is-ethereum-at-risk-of-a-significant-drop-in-price/?utm_source=rss&#038;utm_medium=rss&#038;utm_campaign=is-ethereum-at-risk-of-a-significant-drop-in-price" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/79/17243502.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://en.coin-turk.com/is-ethereum-at-risk-of-a-significant-drop-in-price/?utm_source=rss&#038;utm_medium=rss&#038;utm_campaign=is-ethereum-at-risk-of-a-significant-drop-in-price" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Is Ethereum at Risk of a Significant Drop in Price?
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://timestabloid.com/veteran-wealth-manager-sees-ripple-rising-to-the-level-of-amazon-google-and-apple/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/67/default.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://timestabloid.com/veteran-wealth-manager-sees-ripple-rising-to-the-level-of-amazon-google-and-apple/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Veteran Wealth Manager Sees Ripple Rising to the Level of Amazon, Google, and Apple
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://cointelegraph.com/news/us-treasury-irs-cryptocurrency-regulation-brokers" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/16/17243499.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://cointelegraph.com/news/us-treasury-irs-cryptocurrency-regulation-brokers" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> US Treasury, IRS propose cryptocurrency regulations for brokers
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://u.today/shibarium-from-shiba-inu-celebrates-major-success-heres-what-it-is-this-time" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/64/17243588.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://u.today/shibarium-from-shiba-inu-celebrates-major-success-heres-what-it-is-this-time" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Shibarium From Shiba Inu Celebrates Major Success: Here’s What This Time
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://coinedition.com/polygon-rebuts-allegations-of-involvement-in-matic-crash/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/7/default.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://coinedition.com/polygon-rebuts-allegations-of-involvement-in-matic-crash/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Polygon Rebuts Allegations of Involvement in MATIC Crash
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://www.cryptonewsz.com/cardano-role-in-entertainment-transformation/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/cryptonewsz.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://www.cryptonewsz.com/cardano-role-in-entertainment-transformation/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Cardano role in entertainment transformation
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://www.coindesk.com/policy/2023/09/07/blanket-crypto-bans-wont-work-joint-imf-fsb-paper-warns/?utm_medium=referral&utm_source=rss&utm_campaign=headlines" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/5/17243690.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://www.coindesk.com/policy/2023/09/07/blanket-crypto-bans-wont-work-joint-imf-fsb-paper-warns/?utm_medium=referral&utm_source=rss&utm_campaign=headlines" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Blanket Crypto Bans Won’t Work, Joint IMF, FSB Paper Warns
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://en.coinotag.com/asset-managers-eye-explosive-growth-in-bitcoin-crypto-sector-despite-market-slumps/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/77/default.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://en.coinotag.com/asset-managers-eye-explosive-growth-in-bitcoin-crypto-sector-despite-market-slumps/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Asset Managers Eye Explosive Growth in Bitcoin &amp; Crypto Sector Despite Market Slumps
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://www.coindesk.com/business/2023/09/07/depressed-crypto-markets-regulatory-risks-fail-to-dissuade-asset-managers-from-investing/?utm_medium=referral&utm_source=rss&utm_campaign=headlines" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/5/17243236.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://www.coindesk.com/business/2023/09/07/depressed-crypto-markets-regulatory-risks-fail-to-dissuade-asset-managers-from-investing/?utm_medium=referral&utm_source=rss&utm_campaign=headlines" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Depressed Crypto Markets, Regulatory Risks Fail to Dissuade Asset Managers From Investing
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://cointelegraph.com/news/google-mandatory-ai-disclosure-political-campaign-ads" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/16/17243047.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://cointelegraph.com/news/google-mandatory-ai-disclosure-political-campaign-ads" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Google makes AI disclosure mandatory in political campaign ads
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://cryptodaily.co.uk/2023/09/avalanche-incorporates-arkefi-platform-backed-by-allianceblock" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/75/17242863.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://cryptodaily.co.uk/2023/09/avalanche-incorporates-arkefi-platform-backed-by-allianceblock" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Avalanche Incorporates Arkefi Platform Backed by AllianceBlock
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://cryptonews.com/news/failed-crypto-lender-celsius-attempts-recoup-assets-from-equitiesfirst-holdings.htm" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/52/17242866.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://cryptonews.com/news/failed-crypto-lender-celsius-attempts-recoup-assets-from-equitiesfirst-holdings.htm" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Failed Crypto Lender Celsius Attempts to Recoup Assets From EquitiesFirst Holdings
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://en.bitcoinsistemi.com/bitcoin-exchange-binance-will-not-charge-fees-for-spot-transactions-made-with-the-currencies-of-these-three-countries/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/81/17242704.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://en.bitcoinsistemi.com/bitcoin-exchange-binance-will-not-charge-fees-for-spot-transactions-made-with-the-currencies-of-these-three-countries/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Bitcoin Exchange Binance Will Not Charge Fees for Spot Transactions Made with the
                            Currencies of These Three Countries!
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://cointelegraph.com/news/casa-wallet-launches-ethereum-transaction-relay-service" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/16/17242420.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://cointelegraph.com/news/casa-wallet-launches-ethereum-transaction-relay-service" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Casa wallet launches Ethereum vault relay service for increased user privacy
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://www.tipranks.com/news/cathie-woods-ark-eyes-spot-ethereum-etf-eth-usd?utm_source=cryptocompare.com&utm_medium=referral" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/tipranks.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://www.tipranks.com/news/cathie-woods-ark-eyes-spot-ethereum-etf-eth-usd?utm_source=cryptocompare.com&utm_medium=referral" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Cathie Woods’ ARK Eyes Spot Ethereum ETF (ETH-USD)
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://en.coin-turk.com/chainlink-accumulates-link-as-whales-show-interest-in-the-altcoin/?utm_source=rss&#038;utm_medium=rss&#038;utm_campaign=chainlink-accumulates-link-as-whales-show-interest-in-the-altcoin" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/79/17242246.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://en.coin-turk.com/chainlink-accumulates-link-as-whales-show-interest-in-the-altcoin/?utm_source=rss&#038;utm_medium=rss&#038;utm_campaign=chainlink-accumulates-link-as-whales-show-interest-in-the-altcoin" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Chainlink Accumulates LINK as Whales Show Interest in the Altcoin
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://www.cryptopolitan.com/solana-price-prediction/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/43/39039.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://www.cryptopolitan.com/solana-price-prediction/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Solana Price Prediction 2023-2032: Is SOL a Good Investment?
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://thecryptobasic.com/2023/09/07/ripplex-announces-latest-version-of-xrp-ledger-xrpl-rippled-1-12-0/?utm_source=rss&#038;utm_medium=rss&#038;utm_campaign=ripplex-announces-latest-version-of-xrp-ledger-xrpl-rippled-1-12-0" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/thecryptobasic.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://thecryptobasic.com/2023/09/07/ripplex-announces-latest-version-of-xrp-ledger-xrpl-rippled-1-12-0/?utm_source=rss&#038;utm_medium=rss&#038;utm_campaign=ripplex-announces-latest-version-of-xrp-ledger-xrpl-rippled-1-12-0" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> RippleX Announces Latest Version of XRP Ledger (XRPL), rippled 1.12.0
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://www.newsbtc.com/news/bitcoin/bitcoin-a-buy-or-sell-ark-invest-market-analysis/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/21/17242065.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://www.newsbtc.com/news/bitcoin/bitcoin-a-buy-or-sell-ark-invest-market-analysis/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Is Bitcoin A Buy Or Sell? Ark Invest Shares Market Analysis
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://news.bitcoin.com/blockchain-firm-raises-millions-from-andreessen-horowitz-paris-hilton-to-help-monetize-ai-mashups/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/14/17242069.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://news.bitcoin.com/blockchain-firm-raises-millions-from-andreessen-horowitz-paris-hilton-to-help-monetize-ai-mashups/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Blockchain Firm Raises Millions From Andreessen Horowitz, Paris Hilton to Help Monetize
                            AI Mashups
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://ambcrypto.com/xmr-bulls-stand-firm-amid-a-sluggish-market-is-a-rally-on-the-cards/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/ambcrypto.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://ambcrypto.com/xmr-bulls-stand-firm-amid-a-sluggish-market-is-a-rally-on-the-cards/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> XMR bulls stand firm amid a sluggish market: Is a rally on the cards?
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://www.cryptopolitan.com/anoma-foundation-announces-launch-of-namada/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/cryptopolitan.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://www.cryptopolitan.com/anoma-foundation-announces-launch-of-namada/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Anoma Foundation announces the launch of Namada: The forefront of multichain privacy
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://cryptonews.com/exclusives/ethereum-layer-2-activity-near-all-time-high-l2s-become-the-new-norm.htm" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/52/17242055.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://cryptonews.com/exclusives/ethereum-layer-2-activity-near-all-time-high-l2s-become-the-new-norm.htm" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Ethereum Layer 2 Activity Near All-Time High: L2s Become the New Norm
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://coinpedia.org/crypto-live-news/nigerias-web3-education-focuses-on-overcoming-language-barriers/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/coinpedia.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://coinpedia.org/crypto-live-news/nigerias-web3-education-focuses-on-overcoming-language-barriers/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Nigeria’s Web3 Education Focuses on Overcoming Language Barriers!
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://www.cryptonewsz.com/unlocking-digital-delight-solana-influence-in-entertainment/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/cryptonewsz.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://www.cryptonewsz.com/unlocking-digital-delight-solana-influence-in-entertainment/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Unlocking digital delight: Solana influence in entertainment
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://coinpedia.org/press-release/bitcoin-spark-the-secret-weapon-every-crypto-investor/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/coinpedia.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://coinpedia.org/press-release/bitcoin-spark-the-secret-weapon-every-crypto-investor/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Bitcoin Spark: The Secret Weapon Every Crypto Investor Needs Against Dogecoin and Polygon
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://cointelegraph.com/news/web-3-nigeria-education-efforts-tackle-language-challenge" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/16/17241516.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://cointelegraph.com/news/web-3-nigeria-education-efforts-tackle-language-challenge" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Nigeria’s Web3 education efforts seek to tackle language challenges
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://coinpedia.org/price-analysis/bnb-price-analysis-with-a-downtrend-with-ranges-will-bnb-price-breakout-to-cross-260/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/coinpedia.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://coinpedia.org/price-analysis/bnb-price-analysis-with-a-downtrend-with-ranges-will-bnb-price-breakout-to-cross-260/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> BNB Price Analysis: With A Downtrend With Ranges, Will BNB Price Breakout To Cross $260?
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://www.cryptoglobe.com/latest/2023/09/anthony-pompliano-discusses-sec-rulings-spot-bitcoin-etfs-and-market-trends-on-fox-business/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/2/17241707.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://www.cryptoglobe.com/latest/2023/09/anthony-pompliano-discusses-sec-rulings-spot-bitcoin-etfs-and-market-trends-on-fox-business/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Anthony Pompliano Discusses SEC Rulings, Spot Bitcoin ETFs, and Market Trends on Fox
                            Business
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://invezz.com/news/2023/09/07/allianceblock-launches-arkefi-an-rwa-platform-on-avalanche/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/69/default.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://invezz.com/news/2023/09/07/allianceblock-launches-arkefi-an-rwa-platform-on-avalanche/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> AllianceBlock launches Arkefi, an RWA platform, on Avalanche
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://thecryptobasic.com/2023/09/07/ripple-expands-ubri-to-spain-italy-france-and-ireland/?utm_source=rss&#038;utm_medium=rss&#038;utm_campaign=ripple-expands-ubri-to-spain-italy-france-and-ireland" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/thecryptobasic.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://thecryptobasic.com/2023/09/07/ripple-expands-ubri-to-spain-italy-france-and-ireland/?utm_source=rss&#038;utm_medium=rss&#038;utm_campaign=ripple-expands-ubri-to-spain-italy-france-and-ireland" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Ripple Expands UBRI to Spain, Italy, France and Ireland
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://en.coinotag.com/coinbase-ventures-into-crypto-lending-implications-and-prospects/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/77/default.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://en.coinotag.com/coinbase-ventures-into-crypto-lending-implications-and-prospects/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Coinbase Ventures into Crypto Lending: Implications and Prospects
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://coinpedia.org/crypto-live-news/mirae-asset-securities-partners-with-polygon-labs/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/coinpedia.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://coinpedia.org/crypto-live-news/mirae-asset-securities-partners-with-polygon-labs/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Mirae Asset Securities Partners with Polygon Labs!
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://en.coinotag.com/crucial-crypto-bills-face-uphill-battle-in-congress-what-you-need-to-know/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/77/default.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://en.coinotag.com/crucial-crypto-bills-face-uphill-battle-in-congress-what-you-need-to-know/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Crucial Crypto Bills Face Uphill Battle in Congress: What You Need to Know
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://dailyhodl.com/2023/09/07/stagnant-bitcoin-price-hinting-at-incoming-market-turbulence-says-bloomberg-strategist-heres-what-he-means/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/dailyhodl.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://dailyhodl.com/2023/09/07/stagnant-bitcoin-price-hinting-at-incoming-market-turbulence-says-bloomberg-strategist-heres-what-he-means/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Stagnant Bitcoin Price Hinting at Incoming Market Turbulence, Says Bloomberg Strategist –
                            Here’s What He Means
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://coinpedia.org/altcoin/ethereum-etfs-gain-momentum-is-this-right-time-to-buy-eth/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/coinpedia.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://coinpedia.org/altcoin/ethereum-etfs-gain-momentum-is-this-right-time-to-buy-eth/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Ethereum ETFs Gain Momentum: Is This Right Time To Buy ETH?
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://coinpedia.org/crypto-live-news/visa-expands-payment-network-to-include-blockchain-cbdcs-stablecoins/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/coinpedia.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://coinpedia.org/crypto-live-news/visa-expands-payment-network-to-include-blockchain-cbdcs-stablecoins/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Visa Expands Payment Network to Include Blockchain, CBDCs, Stablecoins!
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://www.cryptopolitan.com/avalanche-ecosystem-gets-an-upgrade-with-arkefi-allianceblock-powered-art-cars-and-exclusive-collectible-investment-platform/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/cryptopolitan.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://www.cryptopolitan.com/avalanche-ecosystem-gets-an-upgrade-with-arkefi-allianceblock-powered-art-cars-and-exclusive-collectible-investment-platform/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Avalanche Ecosystem Gets an Upgrade with Arkefi: AllianceBlock-Powered Art, Cars, and
                            Exclusive Collectible Investment Platform
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://thecryptobasic.com/2023/09/07/ripple-enhances-xrp-use-case-through-this-project/?utm_source=rss&#038;utm_medium=rss&#038;utm_campaign=ripple-enhances-xrp-use-case-through-this-project" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://images.cryptocompare.com/news/default/thecryptobasic.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://thecryptobasic.com/2023/09/07/ripple-enhances-xrp-use-case-through-this-project/?utm_source=rss&#038;utm_medium=rss&#038;utm_campaign=ripple-enhances-xrp-use-case-through-this-project" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Ripple Enhances XRP Use Case Through This Project
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://cryptonews.com/news/fasb-approves-new-accounting-standards-for-crypto-holdings.htm" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/52/17240972.jpeg" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://cryptonews.com/news/fasb-approves-new-accounting-standards-for-crypto-holdings.htm" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> FASB Approves New Accounting Standards for Crypto Holdings
                          </div>
                          <div style={{ background: 'transparent', overflow: 'hidden', borderBottom: '1px solid rgb(224, 230, 239)', color: 'rgb(0, 0, 0)', textDecoration: 'none', display: 'block', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px' }}>
                            <div style={{ lineHeight: '35px', display: 'block', float: 'left' }}> <a href="https://en.coinotag.com/synopsis-summit-is-coming-to-istanbul-on-5-6-october-2023/" style={{ textDecoration: 'none' }} target="_blank"> <img src="https://resources.cryptocompare.com/news/77/default.png" style={{ marginRight: '5px', width: '50px', height: '50px', borderRadius: '10px', border: 0 }} />
                            </a></div>
                            <div style={{ marginLeft: '70px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', fontSize: '12px' }}>
                              <h3 style={{ margin: 0, marginBottom: '5px', padding: 0, background: 'transparent', border: 0, borderLeft: 0 }}>
                                <a href="https://en.coinotag.com/synopsis-summit-is-coming-to-istanbul-on-5-6-october-2023/" target="_blank" style={{ fontSize: '18px', fontWeight: 'bold', color: '#000', textDecoration: 'none!important' }} />
                              </h3>
                            </div> Synopsis Summit is Coming to Istanbul on 5-6 October 2023
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{/* card */}
            </div>{/* col-8 */}
            <div className="col-lg-4 mg-t-20 mg-lg-t-0">
              <div className="card shadow-base bd-0 overflow-hidden">
                <div className="pd-x-25 pd-t-25">
                  <h6 className="tx-13 tx-uppercase tx-inverse tx-semibold tx-spacing-1 mg-b-20">Storage Overview</h6>
                </div>{/* pd-x-25 */}
                <div className="pd-25" style={{ paddingTop: 0 }}>
                  {/* TradingView Widget BEGIN */}
                  <div className="tradingview-widget-container" style={{ width: '100%', height: '450px' }}>
                    <iframe scrolling="no" allowTransparency="true" frameBorder={0} src="https://www.tradingview-widget.com/embed-widget/technical-analysis/?locale=en#%7B%22interval%22%3A%221m%22%2C%22width%22%3A%22100%25%22%2C%22isTransparent%22%3Afalse%2C%22height%22%3A450%2C%22symbol%22%3A%22NASDAQ%3AAAPL%22%2C%22showIntervalTabs%22%3Atrue%2C%22colorTheme%22%3A%22dark%22%2C%22utm_source%22%3A%22Tintworldtrades..com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22technical-analysis%22%2C%22page-uri%22%3A%22Tintworldtrades..com%2F%3Fa%3Daccount%22%7D" title="technical analysis TradingView widget" lang="en" style={{ userSelect: 'none', boxSizing: 'border-box', display: 'block', height: 'calc(418px)', width: '100%' }} />
                    <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/NASDAQ-AAPL/technicals/?utm_source=Tintworldtrades..com&utm_medium=widget_new&utm_campaign=technical-analysis" rel="noopener" target="_blank"><span className="blue-text">Technical Analysis for AAPL</span></a> by
                      TradingView</div>
                    <style dangerouslySetInnerHTML={{ __html: "\n                  .tradingview-widget-copyright {\n                    font-size: 13px !important;\n                    line-height: 32px !important;\n                    text-align: center !important;\n                    vertical-align: middle !important;\n                    /* @mixin sf-pro-display-font; */\n                    font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif !important;\n                    color: #9db2bd !important;\n                  }\n\n                  .tradingview-widget-copyright .blue-text {\n                    color: #2962FF !important;\n                  }\n\n                  .tradingview-widget-copyright a {\n                    text-decoration: none !important;\n                    color: #9db2bd !important;\n                  }\n\n                  .tradingview-widget-copyright a:visited {\n                    color: #9db2bd !important;\n                  }\n\n                  .tradingview-widget-copyright a:hover .blue-text {\n                    color: #1E53E5 !important;\n                  }\n\n                  .tradingview-widget-copyright a:active .blue-text {\n                    color: #1848CC !important;\n                  }\n\n                  .tradingview-widget-copyright a:visited .blue-text {\n                    color: #2962FF !important;\n                  }\n                " }} />
                  </div>
                  {/* TradingView Widget END */}
                </div>
              </div>{/* card */}
            </div>{/* col-4 */}
          </div>{/* row */}
        </div>{/* br-pagebody */}
        <footer className="br-footer">
          <div className="footer-left">
            <div className="mg-b-2">Copyright © 2020. Tintworldtrades..com Limited | All Rights Reserved.</div>
          </div>
        </footer>
        <div className="resize-sensor" style={{ position: 'absolute', inset: '0px', overflow: 'hidden', zIndex: -1, visibility: 'hidden' }}>
          <div className="resize-sensor-expand" style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, overflow: 'hidden', zIndex: -1, visibility: 'hidden' }}>
            <div style={{ position: 'absolute', left: '0px', top: '0px', transition: 'all 0s ease 0s', width: '100000px', height: '100000px' }}>
            </div>
          </div>
          <div className="resize-sensor-shrink" style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, overflow: 'hidden', zIndex: -1, visibility: 'hidden' }}>
            <div style={{ position: 'absolute', left: 0, top: 0, transition: '0s', width: '200%', height: '200%' }} />
          </div>
        </div>
      </div>{/* br-mainpanel */}
      {/* ########## END: MAIN PANEL ########## */}
      {/* End custom js for this page*/}
      {/* end js include path */}
      <iframe id="tidio-chat-code" title="Tidio Chat code" style={{ display: 'none' }} />
      <div id="ui-datepicker-div" className="ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all" />
      <div id="tidio-chat"><iframe title="Tidio Chat" id="tidio-chat-iframe" style={{ display: 'block', border: 'none', position: 'fixed', inset: 'auto 9px 35px auto', width: '264px', height: '94px', opacity: 1, colorScheme: 'none', background: 'none transparent !important', margin: '0px', maxHeight: '100vh', maxWidth: '100vw', transform: 'translateY(0px)', transition: 'none 0s ease 0s !important', visibility: 'visible', zIndex: '999999999 !important', borderRadius: '47px 30px 47px 47px' }} />
      </div>
    </div>
  )
}

export default Dashboard