import React, { useEffect, useRef, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import {
  BrowserRouter,
  useNavigate,
  Link,
  Route,
  Routes,
  Switch,
} from "react-router-dom";
import Nav from "../Components/Nav";
import Sidebar from "../Components/Sidebar";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
import BreakHeader from '../Components/BreakHeader';

function Withdrawal() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] =
    useContext(GlobalContext);
  const [loading, setloading] = useState(false);
  const [step, setstep] = useState(1);
  const [coin, setcoin] = useState("BITCOIN");
  const [wallet, setwallet] = useState("");
  const [Amount, setAmount] = useState("");

  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false);
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false);
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false);
        } else {
          console.log("No such document!");
          setloading(false);
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const SetcoinTouse = (no) => {
    if (no == 2) {
      setcoin("Tether");
    }
    if (no == "6") {
      setcoin("Ethereum");
    }
    if (no == "7") {
      setcoin("Bitcoin");
    }
    setstep(2);
  };
  const confirmWitdrawal = async () => {
    if (Amount != "") {
      console.log(Amount);
      console.log(userdetails.balance);
      if (parseFloat(userdetails.balance) < parseFloat(Amount)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "balance not enough",
          footer: '<a href="">Why do I have this issue?</a>',
        });
      } else {
        await updatewithdrawalhistory();
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "no Amount selected",
        footer: '<a href="">Why do I have this issue?</a>',
      });
    }
  };

  const updatewithdrawalhistory = async () => {
    if (coin == "" || Amount == "" || wallet == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select wallet, Coin , and Amount to procecced withdrawal",
        footer: '<a href="">Why do I have this issue?</a>',
      });
      return 0;
    }
    // console.log(cointype)
    setloading(true);
    const newBal = parseFloat(userdetails.balance); /*- parseFloat(Amount);*/
    const newEarings = parseFloat(userdetails.balance) - parseFloat(Amount);
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    const d1 = new Date();
    // const increment = firebase.firestore.FieldValue.increment(parseInt(Amount));
    var washingtonRef = db.collection("users").doc(userids);
    await washingtonRef.update({
      // Totalwithdrawal: increment,
      Withdrawalhistory: firebase.firestore.FieldValue.arrayUnion({
        amt: Amount,
        date: d1.getTime(),
        mode: "withdrawal",
        wallet: wallet,
        coin: coin,
        status: "pending",
      }),
    });
   // await sendAdminMaiil();
    await updateUserBalance();
    await CreatWithdrawalObj();
   // await sendAdminMaiil();
  };

  const sendAdminMaiil = async () => {
    var templateParams = {
      message: `${userdetails.fullname} made a withdrawal to his account `,
    };
    await emailjs
      .send(
        "service_5jy1gr4",
        "template_zntnjos",
        templateParams,
        "HNlgG_D2lYE6E-7Q3"
      )
      .then(
        function (response) {
          // setloading(false)
        },
        function (err) {
          // setloading(false)
          alert("FAILED...", err);
        }
      );
  };
  const updateUserBalance = async (bal) => {
    const newEarings = parseFloat(userdetails.balance) - parseFloat(Amount);
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = await db.collection("users").doc(userids);
    washingtonRef
      .update({
        balance: newEarings,
      })
      .then(function () {
        console.log("Document successfully up2dated!");
        // alert("withdrawal was successful we will get back to you");
        Swal.fire({
          icon: "success",
          title: "withdrawal was successful we will get back to you!",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.replace("/dashboard");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
    // fetchuserdata();
  };
  const CreatWithdrawalObj = async () => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    const d1 = new Date();
    let uobj = {
      fullname: userdetails.fullname,
      email: userdetails.email,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      userid: userids,
      info: {
        amt: Amount,
        date: d1.getTime(),
        mode: "withdrawal",
        wallet: wallet,
        coin: coin,
        status: "pending",
      },
    };
    const userdetail1 = await db
      .collection("withdrawals")
      .add(uobj)
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
  return (
    <div>
      <div className="br-logo">
        <a href="?a=account">
          <img
            className="brand-logo-dark"
            src="images/rlogo.png"
            alt=""
            height={45}
            srcSet="images/rlogo.png"
          />
        </a>
      </div>
      <Nav />
      {/* ########## END: LEFT PANEL ########## */}
      <BreakHeader />
      {/* ########## END: HEAD PANEL ########## */}
      {/* ########## START: RIGHT PANEL ########## */}
      <div className="br-sideright">
        <ul className="nav nav-tabs sidebar-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-toggle="tab"
              role="tab"
              href="#calendar"
            >
              <svg
                className="svg-inline--fa fa-calendar fa-w-14 menu-item-icon tx-22"
                aria-hidden="true"
                data-prefix="fa"
                data-icon="calendar"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                data-fa-i2svg
              >
                <path
                  fill="currentColor"
                  d="M12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm436-44v-36c0-26.5-21.5-48-48-48h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v36c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12z"
                />
              </svg>
              {/* <i class="menu-item-icon fa fa-calendar tx-22"></i> */}
            </a>
          </li>
        </ul>
        {/* sidebar-tabs */}
        {/* Tab panes */}
        <div className="tab-content">
          <div
            className="tab-pane pos-absolute a-0 mg-t-60 contact-scrollbar active ps"
            id="contacts"
            role="tabpanel"
          >
            <label className="sidebar-label pd-x-25 mg-t-25">
              Time &amp; Date
            </label>
            <div className="pd-x-25">
              <h2 id="brTime" className="br-time">
                04:48:26 PM
              </h2>
              <h6 id="brDate" className="br-date">
                September 07, 2023 THU
              </h6>
            </div>
            <label className="sidebar-label pd-x-25 mg-t-25"> Calendar</label>
            <div
              className="datepicker sidebar-datepicker hasDatepicker"
              id="dp1694101695736"
            >
              <div
                className="ui-datepicker-inline ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all"
                style={{ display: "block" }}
              >
                <div className="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
                  <a
                    className="ui-datepicker-prev ui-corner-all"
                    data-handler="prev"
                    data-event="click"
                    title="Prev"
                  >
                    <span className="ui-icon ui-icon-circle-triangle-w">
                      Prev
                    </span>
                  </a>
                  <a
                    className="ui-datepicker-next ui-corner-all"
                    data-handler="next"
                    data-event="click"
                    title="Next"
                  >
                    <span className="ui-icon ui-icon-circle-triangle-e">
                      Next
                    </span>
                  </a>
                  <div className="ui-datepicker-title">
                    <span className="ui-datepicker-month">September</span>&nbsp;
                    <span className="ui-datepicker-year">2023</span>
                  </div>
                </div>
                <table className="ui-datepicker-calendar">
                  <thead>
                    <tr>
                      <th scope="col" className="ui-datepicker-week-end">
                        <span title="Sunday">Su</span>
                      </th>
                      <th scope="col">
                        <span title="Monday">Mo</span>
                      </th>
                      <th scope="col">
                        <span title="Tuesday">Tu</span>
                      </th>
                      <th scope="col">
                        <span title="Wednesday">We</span>
                      </th>
                      <th scope="col">
                        <span title="Thursday">Th</span>
                      </th>
                      <th scope="col">
                        <span title="Friday">Fr</span>
                      </th>
                      <th scope="col" className="ui-datepicker-week-end">
                        <span title="Saturday">Sa</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className=" ui-datepicker-week-end ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">
                        &nbsp;
                      </td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">
                        &nbsp;
                      </td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">
                        &nbsp;
                      </td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">
                        &nbsp;
                      </td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">
                        &nbsp;
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          1
                        </a>
                      </td>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          2
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          3
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          4
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          5
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          6
                        </a>
                      </td>
                      <td
                        className=" ui-datepicker-days-cell-over  ui-datepicker-current-day ui-datepicker-today"
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a
                          className="ui-state-default ui-state-highlight ui-state-active ui-state-hover"
                          href="#"
                        >
                          7
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          8
                        </a>
                      </td>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          9
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          10
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          11
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          12
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          13
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          14
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          15
                        </a>
                      </td>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          16
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          17
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          18
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          19
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          20
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          21
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          22
                        </a>
                      </td>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          23
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          24
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          25
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          26
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          27
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          28
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          29
                        </a>
                      </td>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          30
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="ps__rail-x" style={{ left: "0px", top: "0px" }}>
              <div
                className="ps__thumb-x"
                tabIndex={0}
                style={{ left: "0px", width: "0px" }}
              />
            </div>
            <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
              <div
                className="ps__thumb-y"
                tabIndex={0}
                style={{ top: "0px", height: "0px" }}
              />
            </div>
          </div>
          {/* #contacts */}
          <div
            className="tab-pane pos-absolute a-0 mg-t-60 attachment-scrollbar ps"
            id="attachments"
            role="tabpanel"
          >
            <label className="sidebar-label pd-x-25 mg-t-25">
              Recent Attachments
            </label>
            <div className="media-file-list">
              <div className="media">
                <div className="pd-10 bg-gray-500 bg-mojito wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg
                    className="svg-inline--fa fa-file-image fa-w-12 tx-28 tx-white"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="file-image"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="currentColor"
                      d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm32-48h224V288l-23.5-23.5c-4.7-4.7-12.3-4.7-17 0L176 352l-39.5-39.5c-4.7-4.7-12.3-4.7-17 0L80 352v64zm48-240c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z"
                    />
                  </svg>
                  {/* <i class="far fa-file-image tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">IMG_43445</p>
                  <p className="mg-b-0 tx-12 op-5">JPG Image</p>
                  <p className="mg-b-0 tx-12 op-5">1.2mb</p>
                </div>
                {/* media-body */}
                <a href="#" className="more">
                  <i className="icon ion-android-more-vertical tx-18" />
                </a>
              </div>
              {/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-purple wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg
                    className="svg-inline--fa fa-file-video fa-w-12 tx-28 tx-white"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="file-video"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="currentColor"
                      d="M369.941 97.941l-83.882-83.882A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v416c0 26.51 21.49 48 48 48h288c26.51 0 48-21.49 48-48V131.882a48 48 0 0 0-14.059-33.941zM332.118 128H256V51.882L332.118 128zM48 464V48h160v104c0 13.255 10.745 24 24 24h104v288H48zm228.687-211.303L224 305.374V268c0-11.046-8.954-20-20-20H100c-11.046 0-20 8.954-20 20v104c0 11.046 8.954 20 20 20h104c11.046 0 20-8.954 20-20v-37.374l52.687 52.674C286.704 397.318 304 390.28 304 375.986V264.011c0-14.311-17.309-21.319-27.313-11.314z"
                    />
                  </svg>
                  {/* <i class="far fa-file-video tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">VID_6543</p>
                  <p className="mg-b-0 tx-12 op-5">MP4 Video</p>
                  <p className="mg-b-0 tx-12 op-5">24.8mb</p>
                </div>
                {/* media-body */}
                <a href="#" className="more">
                  <i className="icon ion-android-more-vertical tx-18" />
                </a>
              </div>
              {/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-reef wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg
                    className="svg-inline--fa fa-file-word fa-w-12 tx-28 tx-white"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="file-word"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="currentColor"
                      d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm220.1-208c-5.7 0-10.6 4-11.7 9.5-20.6 97.7-20.4 95.4-21 103.5-.2-1.2-.4-2.6-.7-4.3-.8-5.1.3.2-23.6-99.5-1.3-5.4-6.1-9.2-11.7-9.2h-13.3c-5.5 0-10.3 3.8-11.7 9.1-24.4 99-24 96.2-24.8 103.7-.1-1.1-.2-2.5-.5-4.2-.7-5.2-14.1-73.3-19.1-99-1.1-5.6-6-9.7-11.8-9.7h-16.8c-7.8 0-13.5 7.3-11.7 14.8 8 32.6 26.7 109.5 33.2 136 1.3 5.4 6.1 9.1 11.7 9.1h25.2c5.5 0 10.3-3.7 11.6-9.1l17.9-71.4c1.5-6.2 2.5-12 3-17.3l2.9 17.3c.1.4 12.6 50.5 17.9 71.4 1.3 5.3 6.1 9.1 11.6 9.1h24.7c5.5 0 10.3-3.7 11.6-9.1 20.8-81.9 30.2-119 34.5-136 1.9-7.6-3.8-14.9-11.6-14.9h-15.8z"
                    />
                  </svg>
                  {/* <i class="far fa-file-word tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">Tax_Form</p>
                  <p className="mg-b-0 tx-12 op-5">Word Document</p>
                  <p className="mg-b-0 tx-12 op-5">5.5mb</p>
                </div>
                {/* media-body */}
                <a href="#" className="more">
                  <i className="icon ion-android-more-vertical tx-18" />
                </a>
              </div>
              {/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-firewatch wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg
                    className="svg-inline--fa fa-file-pdf fa-w-12 tx-28 tx-white"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="file-pdf"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="currentColor"
                      d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"
                    />
                  </svg>
                  {/* <i class="far fa-file-pdf tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">Getting_Started</p>
                  <p className="mg-b-0 tx-12 op-5">PDF Document</p>
                  <p className="mg-b-0 tx-12 op-5">12.7mb</p>
                </div>
                {/* media-body */}
                <a href="#" className="more">
                  <i className="icon ion-android-more-vertical tx-18" />
                </a>
              </div>
              {/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-firewatch wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg
                    className="svg-inline--fa fa-file-pdf fa-w-12 tx-28 tx-white"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="file-pdf"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="currentColor"
                      d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"
                    />
                  </svg>
                  {/* <i class="far fa-file-pdf tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">Introduction</p>
                  <p className="mg-b-0 tx-12 op-5">PDF Document</p>
                  <p className="mg-b-0 tx-12 op-5">7.7mb</p>
                </div>
                {/* media-body */}
                <a href="#" className="more">
                  <i className="icon ion-android-more-vertical tx-18" />
                </a>
              </div>
              {/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-mojito wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg
                    className="svg-inline--fa fa-file-image fa-w-12 tx-28 tx-white"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="file-image"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="currentColor"
                      d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm32-48h224V288l-23.5-23.5c-4.7-4.7-12.3-4.7-17 0L176 352l-39.5-39.5c-4.7-4.7-12.3-4.7-17 0L80 352v64zm48-240c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z"
                    />
                  </svg>
                  {/* <i class="far fa-file-image tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">IMG_43420</p>
                  <p className="mg-b-0 tx-12 op-5">JPG Image</p>
                  <p className="mg-b-0 tx-12 op-5">2.2mb</p>
                </div>
                {/* media-body */}
                <a href="#" className="more">
                  <i className="icon ion-android-more-vertical tx-18" />
                </a>
              </div>
              {/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-mojito wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg
                    className="svg-inline--fa fa-file-image fa-w-12 tx-28 tx-white"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="file-image"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="currentColor"
                      d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm32-48h224V288l-23.5-23.5c-4.7-4.7-12.3-4.7-17 0L176 352l-39.5-39.5c-4.7-4.7-12.3-4.7-17 0L80 352v64zm48-240c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z"
                    />
                  </svg>
                  {/* <i class="far fa-file-image tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">IMG_43447</p>
                  <p className="mg-b-0 tx-12 op-5">JPG Image</p>
                  <p className="mg-b-0 tx-12 op-5">3.2mb</p>
                </div>
                {/* media-body */}
                <a href="#" className="more">
                  <i className="icon ion-android-more-vertical tx-18" />
                </a>
              </div>
              {/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-purple wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg
                    className="svg-inline--fa fa-file-video fa-w-12 tx-28 tx-white"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="file-video"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="currentColor"
                      d="M369.941 97.941l-83.882-83.882A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v416c0 26.51 21.49 48 48 48h288c26.51 0 48-21.49 48-48V131.882a48 48 0 0 0-14.059-33.941zM332.118 128H256V51.882L332.118 128zM48 464V48h160v104c0 13.255 10.745 24 24 24h104v288H48zm228.687-211.303L224 305.374V268c0-11.046-8.954-20-20-20H100c-11.046 0-20 8.954-20 20v104c0 11.046 8.954 20 20 20h104c11.046 0 20-8.954 20-20v-37.374l52.687 52.674C286.704 397.318 304 390.28 304 375.986V264.011c0-14.311-17.309-21.319-27.313-11.314z"
                    />
                  </svg>
                  {/* <i class="far fa-file-video tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">VID_6545</p>
                  <p className="mg-b-0 tx-12 op-5">AVI Video</p>
                  <p className="mg-b-0 tx-12 op-5">14.8mb</p>
                </div>
                {/* media-body */}
                <a href="#" className="more">
                  <i className="icon ion-android-more-vertical tx-18" />
                </a>
              </div>
              {/* media */}
              <div className="media mg-t-20">
                <div className="pd-10 bg-gray-500 bg-reef wd-50 ht-60 tx-center d-flex align-items-center justify-content-center">
                  <svg
                    className="svg-inline--fa fa-file-word fa-w-12 tx-28 tx-white"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="file-word"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="currentColor"
                      d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm220.1-208c-5.7 0-10.6 4-11.7 9.5-20.6 97.7-20.4 95.4-21 103.5-.2-1.2-.4-2.6-.7-4.3-.8-5.1.3.2-23.6-99.5-1.3-5.4-6.1-9.2-11.7-9.2h-13.3c-5.5 0-10.3 3.8-11.7 9.1-24.4 99-24 96.2-24.8 103.7-.1-1.1-.2-2.5-.5-4.2-.7-5.2-14.1-73.3-19.1-99-1.1-5.6-6-9.7-11.8-9.7h-16.8c-7.8 0-13.5 7.3-11.7 14.8 8 32.6 26.7 109.5 33.2 136 1.3 5.4 6.1 9.1 11.7 9.1h25.2c5.5 0 10.3-3.7 11.6-9.1l17.9-71.4c1.5-6.2 2.5-12 3-17.3l2.9 17.3c.1.4 12.6 50.5 17.9 71.4 1.3 5.3 6.1 9.1 11.6 9.1h24.7c5.5 0 10.3-3.7 11.6-9.1 20.8-81.9 30.2-119 34.5-136 1.9-7.6-3.8-14.9-11.6-14.9h-15.8z"
                    />
                  </svg>
                  {/* <i class="far fa-file-word tx-28 tx-white"></i> */}
                </div>
                <div className="media-body">
                  <p className="mg-b-0 tx-13">Secret_Document</p>
                  <p className="mg-b-0 tx-12 op-5">Word Document</p>
                  <p className="mg-b-0 tx-12 op-5">4.5mb</p>
                </div>
                {/* media-body */}
                <a href="#" className="more">
                  <i className="icon ion-android-more-vertical tx-18" />
                </a>
              </div>
              {/* media */}
            </div>
            {/* media-list */}
            <div className="ps__rail-x" style={{ left: "0px", top: "0px" }}>
              <div
                className="ps__thumb-x"
                tabIndex={0}
                style={{ left: "0px", width: "0px" }}
              />
            </div>
            <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
              <div
                className="ps__thumb-y"
                tabIndex={0}
                style={{ top: "0px", height: "0px" }}
              />
            </div>
          </div>
          {/* #history */}
          <div
            className="tab-pane pos-absolute a-0 mg-t-60 schedule-scrollbar ps"
            id="calendar"
            role="tabpanel"
          >
            <label className="sidebar-label pd-x-25 mg-t-25">
              Time &amp; Date
            </label>
            <div className="pd-x-25">
              <h2 id="brTime" className="br-time" />
              <h6 id="brDate" className="br-date" />
            </div>
            <label className="sidebar-label pd-x-25 mg-t-25">
              Events Calendar
            </label>
            <div
              className="datepicker sidebar-datepicker hasDatepicker"
              id="dp1694101695737"
            >
              <div
                className="ui-datepicker-inline ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all"
                style={{ display: "block" }}
              >
                <div className="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
                  <a
                    className="ui-datepicker-prev ui-corner-all"
                    data-handler="prev"
                    data-event="click"
                    title="Prev"
                  >
                    <span className="ui-icon ui-icon-circle-triangle-w">
                      Prev
                    </span>
                  </a>
                  <a
                    className="ui-datepicker-next ui-corner-all"
                    data-handler="next"
                    data-event="click"
                    title="Next"
                  >
                    <span className="ui-icon ui-icon-circle-triangle-e">
                      Next
                    </span>
                  </a>
                  <div className="ui-datepicker-title">
                    <span className="ui-datepicker-month">September</span>&nbsp;
                    <span className="ui-datepicker-year">2023</span>
                  </div>
                </div>
                <table className="ui-datepicker-calendar">
                  <thead>
                    <tr>
                      <th scope="col" className="ui-datepicker-week-end">
                        <span title="Sunday">Su</span>
                      </th>
                      <th scope="col">
                        <span title="Monday">Mo</span>
                      </th>
                      <th scope="col">
                        <span title="Tuesday">Tu</span>
                      </th>
                      <th scope="col">
                        <span title="Wednesday">We</span>
                      </th>
                      <th scope="col">
                        <span title="Thursday">Th</span>
                      </th>
                      <th scope="col">
                        <span title="Friday">Fr</span>
                      </th>
                      <th scope="col" className="ui-datepicker-week-end">
                        <span title="Saturday">Sa</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className=" ui-datepicker-week-end ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">
                        &nbsp;
                      </td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">
                        &nbsp;
                      </td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">
                        &nbsp;
                      </td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">
                        &nbsp;
                      </td>
                      <td className=" ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">
                        &nbsp;
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          1
                        </a>
                      </td>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          2
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          3
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          4
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          5
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          6
                        </a>
                      </td>
                      <td
                        className=" ui-datepicker-days-cell-over  ui-datepicker-current-day ui-datepicker-today"
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a
                          className="ui-state-default ui-state-highlight ui-state-active ui-state-hover"
                          href="#"
                        >
                          7
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          8
                        </a>
                      </td>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          9
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          10
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          11
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          12
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          13
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          14
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          15
                        </a>
                      </td>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          16
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          17
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          18
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          19
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          20
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          21
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          22
                        </a>
                      </td>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          23
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          24
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          25
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          26
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          27
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          28
                        </a>
                      </td>
                      <td
                        className=" "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          29
                        </a>
                      </td>
                      <td
                        className=" ui-datepicker-week-end "
                        data-handler="selectDay"
                        data-event="click"
                        data-month={8}
                        data-year={2023}
                      >
                        <a className="ui-state-default" href="#">
                          30
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <label className="sidebar-label pd-x-25 mg-t-25">Event Today</label>
            <div className="pd-x-25">
              <div className="list-group sidebar-event-list mg-b-20">
                <div className="list-group-item">
                  <div>
                    <h6>Roven's 32th Birthday</h6>
                    <p>2:30PM</p>
                  </div>
                  <a href="#" className="more">
                    <i className="icon ion-android-more-vertical tx-18" />
                  </a>
                </div>
                {/* list-group-item */}
                <div className="list-group-item">
                  <div>
                    <h6>Regular Workout Schedule</h6>
                    <p>7:30PM</p>
                  </div>
                  <a href="#" className="more">
                    <i className="icon ion-android-more-vertical tx-18" />
                  </a>
                </div>
                {/* list-group-item */}
              </div>
              {/* list-group */}
              <a
                href="#"
                className="btn btn-block btn-outline-secondary tx-uppercase tx-12 tx-spacing-2"
              >
                + Add Event
              </a>
              <br />
            </div>
            <div className="ps__rail-x" style={{ left: "0px", top: "0px" }}>
              <div
                className="ps__thumb-x"
                tabIndex={0}
                style={{ left: "0px", width: "0px" }}
              />
            </div>
            <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
              <div
                className="ps__thumb-y"
                tabIndex={0}
                style={{ top: "0px", height: "0px" }}
              />
            </div>
          </div>
          <div
            className="tab-pane pos-absolute a-0 mg-t-60 settings-scrollbar ps"
            id="settings"
            role="tabpanel"
          >
            <label className="sidebar-label pd-x-25 mg-t-25">
              Quick Settings
            </label>
            <div className="sidebar-settings-item">
              <h6 className="tx-13 tx-normal">Sound Notification</h6>
              <p className="op-5 tx-13">
                Play an alert sound everytime there is a new notification.
              </p>
              <div className="br-switchbutton checked">
                <input type="hidden" name="switch1" defaultValue="true" />
                <span />
              </div>
              {/* br-switchbutton */}
            </div>
            <div className="sidebar-settings-item">
              <h6 className="tx-13 tx-normal">2 Steps Verification</h6>
              <p className="op-5 tx-13">
                Sign in using a two step verification by sending a verification
                code to your phone.
              </p>
              <div className="br-switchbutton">
                <input type="hidden" name="switch2" defaultValue="false" />
                <span />
              </div>
              {/* br-switchbutton */}
            </div>
            <div className="sidebar-settings-item">
              <h6 className="tx-13 tx-normal">Location Services</h6>
              <p className="op-5 tx-13">Allowing us to access your location</p>
              <div className="br-switchbutton">
                <input type="hidden" name="switch3" defaultValue="false" />
                <span />
              </div>
              {/* br-switchbutton */}
            </div>
            <div className="sidebar-settings-item">
              <h6 className="tx-13 tx-normal">Newsletter Subscription</h6>
              <p className="op-5 tx-13">
                Enables you to send us news and updates send straight to your
                email.
              </p>
              <div className="br-switchbutton checked">
                <input type="hidden" name="switch4" defaultValue="true" />
                <span />
              </div>
              {/* br-switchbutton */}
            </div>
            <div className="sidebar-settings-item">
              <h6 className="tx-13 tx-normal">Your email</h6>
              <div className="pos-relative">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  defaultValue="admin@Tintworldtrades..com"
                />
              </div>
            </div>
            <div className="pd-y-20 pd-x-25">
              <h6 className="tx-13 tx-normal tx-white mg-b-20">
                More Settings
              </h6>
              <a
                href="#"
                className="btn btn-block btn-outline-secondary tx-uppercase tx-11 tx-spacing-2"
              >
                Account Settings
              </a>
              <a
                href="#"
                className="btn btn-block btn-outline-secondary tx-uppercase tx-11 tx-spacing-2"
              >
                Privacy Settings
              </a>
            </div>
            <div className="ps__rail-x" style={{ left: "0px", top: "0px" }}>
              <div
                className="ps__thumb-x"
                tabIndex={0}
                style={{ left: "0px", width: "0px" }}
              />
            </div>
            <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
              <div
                className="ps__thumb-y"
                tabIndex={0}
                style={{ top: "0px", height: "0px" }}
              />
            </div>
          </div>
        </div>
        {/* tab-content */}
      </div>
      {/* br-sideright */}
      {/* ########## END: RIGHT PANEL ########## -*/}
      {/* ########## START: MAIN PANEL ########## */}
      <div className="br-mainpanel">
        <div className="br-pagetitle">
          <i className="icon ion-archive" />
          <div>
            <h4>Request Withdrawals</h4>
            <p className="mg-b-0">
              Do bigger things with Tintworldtrades..com.
            </p>
          </div>
        </div>
        <div className="br-pagebody">
          {/* row */}
          <div
            className="row no-gutters shadow-base mg-b-10"
            style={{ marginTop: "10px" }}
          >
            <div className="col-sm-12 col-lg-12">
              <div className="card">
                <div className="card-footer">
                  <div
                    className="ccc-widget ccc-header-v3"
                    id="marquee-container"
                    style={{
                      background: "rgb(255, 255, 255)",
                      boxSizing: "border-box",
                      lineHeight: 1,
                      overflow: "hidden",
                    }}
                  >
 
                  </div>
                </div>
                {/* card-footer */}
              </div>
              {/* card */}
            </div>
            {/* col-3 */}
          </div>
          <div className="br-section-wrapper">
            <h3>Ask for withdrawal:</h3>
            <br />
            <form method="post" name="spendform">
              <input
                type="hidden"
                name="form_id"
                defaultValue={16862959794119}
              />
              <input
                type="hidden"
                name="form_token"
                defaultValue="08bae86c7de245e360aca85c0268335b"
              />
              <input type="hidden" name="a" defaultValue="deposit" />
              <div className="col-md-12">
                {/* Field wrapper start */}
                <div className="field-wrapper">
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={userdetails.balance}
                    disabled
                  />
                  <div className="field-placeholder">Your Account Balance</div>
                </div>
                {/* Field wrapper end */}
              </div>

              <div className="col-md-12">
                {/* Field wrapper start */}
                <div className="field-wrapper">
                  <select
                    className="form-select"
                    id="formSelect"
                    onChange={(e) => {
                      SetcoinTouse(e.target.value);
                    }}
                    name="type"
                  >
                    <option value="6" checked>
                      Bitcoin
                    </option>
                    <option value="7">Tether USDT TRC20</option>
                  </select>
                  <div className="field-placeholder">Select Payment Method</div>
                </div>
                {/* Field wrapper end */}
              </div>
              <div className="col-md-12">
                {/* Field wrapper start */}
                <div className="field-wrapper">
                  <input
                    className="form-control"
                    type="text"
                    name="address"
                    onChange={(e) => {
                      setwallet(e.target.value);
                    }}
                  />
                  <div className="field-placeholder">Enter Wallet Address</div>
                </div>
                {/* Field wrapper end */}
              </div>
              <div className="col-md-12">
                {/* Field wrapper start */}
                <div className="field-wrapper">
                  <input
                    className="form-control"
                    type="text"
                    name="amount"
                    //defaultValue={100.0}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                  <div className="field-placeholder">Enter Amount</div>
                </div>
                {/* Field wrapper end */}
              </div>
              <div className="col-md-12">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={confirmWitdrawal}
                >
                  withdrawal
                </button>
              </div>
            </form>
          </div>
        </div>
        <footer className="br-footer">
          <div className="footer-left">
            <div className="mg-b-2">
              Copyright © 2020. Tintworldtrades..com Limited | All Rights
              Reserved.
            </div>
          </div>
        </footer>
      </div>
      {/* br-mainpanel */}
      {/* End custom js for this page*/}
      {/* end js include path */}
      <div
        id="ui-datepicker-div"
        className="ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all"
      />
      <div
        id="goog-gt-tt"
        className="VIpgJd-yAWNEb-L7lbkb skiptranslate"
        style={{
          borderRadius: "12px",
          margin: "0 0 0 -23px",
          padding: 0,
          fontFamily: '"Google Sans", Arial, sans-serif',
        }}
        data-id
      >
        <div id="goog-gt-vt" className="VIpgJd-yAWNEb-hvhgNd">
          <div className=" VIpgJd-yAWNEb-hvhgNd-l4eHX-i3jM8c">
            <img
              src="https://fonts.gstatic.com/s/i/productlogos/translate/v14/24px.svg"
              width={24}
              height={24}
              alt=""
            />
          </div>
          <div className=" VIpgJd-yAWNEb-hvhgNd-k77Iif-i3jM8c">
            <div className="VIpgJd-yAWNEb-hvhgNd-IuizWc" dir="ltr">
              Original text
            </div>
            <div
              id="goog-gt-original-text"
              className="VIpgJd-yAWNEb-nVMfcd-fmcmS VIpgJd-yAWNEb-hvhgNd-axAV1"
            />
          </div>
          <div className="VIpgJd-yAWNEb-hvhgNd-N7Eqid ltr">
            <div className="VIpgJd-yAWNEb-hvhgNd-N7Eqid-B7I4Od ltr" dir="ltr">
              <div className="VIpgJd-yAWNEb-hvhgNd-UTujCb">
                Rate this translation
              </div>
              <div className="VIpgJd-yAWNEb-hvhgNd-eO9mKe">
                Your feedback will be used to help improve Google Translate
              </div>
            </div>
            <div className="VIpgJd-yAWNEb-hvhgNd-xgov5 ltr">
              <button
                id="goog-gt-thumbUpButton"
                type="button"
                className="VIpgJd-yAWNEb-hvhgNd-bgm6sf"
                title="Good translation"
                aria-label="Good translation"
                aria-pressed="false"
              >
                <span id="goog-gt-thumbUpIcon">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    focusable="false"
                    className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
                  >
                    <path d="M21 7h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 0S7.08 6.85 7 7H2v13h16c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73V9c0-1.1-.9-2-2-2zM7 18H4V9h3v9zm14-7l-3 7H9V8l4.34-4.34L12 9h9v2z" />
                  </svg>
                </span>
                <span id="goog-gt-thumbUpIconFilled">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    focusable="false"
                    className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
                  >
                    <path d="M21 7h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 0S7.08 6.85 7 7v13h11c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73V9c0-1.1-.9-2-2-2zM5 7H1v13h4V7z" />
                  </svg>
                </span>
              </button>
              <button
                id="goog-gt-thumbDownButton"
                type="button"
                className="VIpgJd-yAWNEb-hvhgNd-bgm6sf"
                title="Poor translation"
                aria-label="Poor translation"
                aria-pressed="false"
              >
                <span id="goog-gt-thumbDownIcon">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    focusable="false"
                    className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
                  >
                    <path d="M3 17h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 24s7.09-6.85 7.17-7h5V4H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2zM17 6h3v9h-3V6zM3 13l3-7h9v10l-4.34 4.34L12 15H3v-2z" />
                  </svg>
                </span>
                <span id="goog-gt-thumbDownIconFilled">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    focusable="false"
                    className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
                  >
                    <path d="M3 17h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 24s7.09-6.85 7.17-7V4H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2zm16 0h4V4h-4v13z" />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div
            id="goog-gt-votingHiddenPane"
            className="VIpgJd-yAWNEb-hvhgNd-aXYTce"
          >
            <form
              id="goog-gt-votingForm"
              action="//translate.googleapis.com/translate_voting?client=te"
              method="post"
              target="votingFrame"
              className="VIpgJd-yAWNEb-hvhgNd-aXYTce"
            >
              <input type="text" name="sl" id="goog-gt-votingInputSrcLang" />
              <input type="text" name="tl" id="goog-gt-votingInputTrgLang" />
              <input type="text" name="query" id="goog-gt-votingInputSrcText" />
              <input
                type="text"
                name="gtrans"
                id="goog-gt-votingInputTrgText"
              />
              <input type="text" name="vote" id="goog-gt-votingInputVote" />
            </form>
            <iframe name="votingFrame" frameBorder={0} />
          </div>
        </div>
      </div>
      <iframe
        id="tidio-chat-code"
        title="Tidio Chat code"
        style={{ display: "none" }}
      />
      <div className="VIpgJd-ZVi9od-aZ2wEe-wOHMyf">
        <div className="VIpgJd-ZVi9od-aZ2wEe-OiiCO">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="VIpgJd-ZVi9od-aZ2wEe"
            width="96px"
            height="96px"
            viewBox="0 0 66 66"
          >
            <circle
              className="VIpgJd-ZVi9od-aZ2wEe-Jt5cK"
              fill="none"
              strokeWidth={6}
              strokeLinecap="round"
              cx={33}
              cy={33}
              r={30}
            />
          </svg>
        </div>
      </div>
      <div id="tidio-chat">
        <iframe
          title="Tidio Chat"
          id="tidio-chat-iframe"
          style={{
            display: "block",
            border: "none",
            position: "fixed",
            inset: "auto -7px 15px auto",
            width: "94px",
            height: "94px",
            opacity: 1,
            colorScheme: "none",
            background: "none transparent !important",
            margin: "0px",
            maxHeight: "100vh",
            maxWidth: "100vw",
            transform: "translateY(0px)",
            transition: "none 0s ease 0s !important",
            visibility: "visible",
            zIndex: "999999999 !important",
            borderRadius: "0px",
          }}
        />
      </div>
    </div>
  );
}

export default Withdrawal;
