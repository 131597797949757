import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Link, Route, Routes, Switch } from "react-router-dom";
import { GlobalState } from "./Globalstate";
import AppContainer from "./AppContainer";
import Dashboard from "./Pages/Dashboard";
import Deposit from "./Pages/Deposit";
import Profile from "./Pages/Profile";
import Refferals from "./Pages/Refferals";
import Transactions from "./Pages/Transactions";
import Withdrawal from "./Pages/Withdrawal";
import "./App.css"


function App() {

  return (
    <GlobalState>
      {/* <ToastContainer className="foo" /> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<AppContainer />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/Profile" element={<Profile/>} />
          <Route path="/Refferals" element={<Refferals />} />
          <Route path="/Transactions" element={<Transactions/>} />
          <Route path="/Withdrawal" element={<Withdrawal/>} />
        </Routes>
      </BrowserRouter>
    </GlobalState>
  );
}

export default App;
