import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
function Nav() {
  const navigate = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  const [step, setstep] = useState(1)

  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          navigate("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };
  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    const let3 = await navigate("/");
  };



    return (
        <>
                               <div className="br-sideleft sideleft-scrollbar ps ps--active-y">
        <label className="sidebar-label pd-x-10 mg-t-20 op-3">Navigation</label>
        <ul className="br-sideleft-menu">
          <li className="br-menu-item">
            <a href="dashboard" className="br-menu-link active">
              <svg className="svg-inline--fa fa-home fa-w-18 menu-item-icon tx-22" aria-hidden="true" data-prefix="fas" data-icon="home" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg>
                <path fill="currentColor" d="M488 312.7V456c0 13.3-10.7 24-24 24H348c-6.6 0-12-5.4-12-12V356c0-6.6-5.4-12-12-12h-72c-6.6 0-12 5.4-12 12v112c0 6.6-5.4 12-12 12H112c-13.3 0-24-10.7-24-24V312.7c0-3.6 1.6-7 4.4-9.3l188-154.8c4.4-3.6 10.8-3.6 15.3 0l188 154.8c2.7 2.3 4.3 5.7 4.3 9.3zm83.6-60.9L488 182.9V44.4c0-6.6-5.4-12-12-12h-56c-6.6 0-12 5.4-12 12V117l-89.5-73.7c-17.7-14.6-43.3-14.6-61 0L4.4 251.8c-5.1 4.2-5.8 11.8-1.6 16.9l25.5 31c4.2 5.1 11.8 5.8 16.9 1.6l235.2-193.7c4.4-3.6 10.8-3.6 15.3 0l235.2 193.7c5.1 4.2 12.7 3.5 16.9-1.6l25.5-31c4.2-5.2 3.4-12.7-1.7-16.9z">
                </path>
              </svg>{/* <i class="menu-item-icon fas fas fa-home tx-22"></i> */}
              <span className="menu-item-label">Dashboard</span>
            </a>{/* br-menu-link */}
          </li>{/* br-menu-item */}
          <li className="br-menu-item">
            <a href="deposit" className="br-menu-link ">
              <svg className="svg-inline--fa fa-download fa-w-16 menu-item-icon tx-22" aria-hidden="true" data-prefix="fas" data-icon="download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                <path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z">
                </path>
              </svg>{/* <i class="menu-item-icon fas fa-download tx-22"></i> */}
              <span className="menu-item-label">Deposit</span>
            </a>{/* br-menu-link */}
          </li>{/* br-menu-item */}
          <li className="br-menu-item">
            <a href="Withdrawal" className="br-menu-link ">
              <svg className="svg-inline--fa fa-chart-area fa-w-16 menu-item-icon tx-22" aria-hidden="true" data-prefix="fas" data-icon="chart-area" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                <path fill="currentColor" d="M500 384c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v308h436zM372.7 159.5L288 216l-85.3-113.7c-5.1-6.8-15.5-6.3-19.9 1L96 248v104h384l-89.9-187.8c-3.2-6.5-11.4-8.7-17.4-4.7z">
                </path>
              </svg>{/* <i class="menu-item-icon fas fa-chart-area tx-22"></i> */}
              <span className="menu-item-label">Withdrawal</span>
            </a>{/* br-menu-link */}
          </li>{/* br-menu-item */}
          <li className="br-menu-item">
            <a href="Transactions" className="br-menu-link ">
              <svg className="svg-inline--fa fa-clone fa-w-16 menu-item-icon tx-22" aria-hidden="true" data-prefix="far" data-icon="clone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                <path fill="currentColor" d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z">
                </path>
              </svg>{/* <i class="menu-item-icon far fa-clone tx-22"></i> */}
              <span className="menu-item-label">Transactions</span>
            </a>{/* br-menu-link */}
          </li>{/* br-menu-item */}
          <li className="br-menu-item">
            <a href="Refferals" className="br-menu-link ">
              <svg className="svg-inline--fa fa-retweet fa-w-20 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="retweet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg>
                <path fill="currentColor" d="M629.657 343.598L528.971 444.284c-9.373 9.372-24.568 9.372-33.941 0L394.343 343.598c-9.373-9.373-9.373-24.569 0-33.941l10.823-10.823c9.562-9.562 25.133-9.34 34.419.492L480 342.118V160H292.451a24.005 24.005 0 0 1-16.971-7.029l-16-16C244.361 121.851 255.069 96 276.451 96H520c13.255 0 24 10.745 24 24v222.118l40.416-42.792c9.285-9.831 24.856-10.054 34.419-.492l10.823 10.823c9.372 9.372 9.372 24.569-.001 33.941zm-265.138 15.431A23.999 23.999 0 0 0 347.548 352H160V169.881l40.416 42.792c9.286 9.831 24.856 10.054 34.419.491l10.822-10.822c9.373-9.373 9.373-24.569 0-33.941L144.971 67.716c-9.373-9.373-24.569-9.373-33.941 0L10.343 168.402c-9.373 9.373-9.373 24.569 0 33.941l10.822 10.822c9.562 9.562 25.133 9.34 34.419-.491L96 169.881V392c0 13.255 10.745 24 24 24h243.549c21.382 0 32.09-25.851 16.971-40.971l-16.001-16z">
                </path>
              </svg>{/* <i class="menu-item-icon fa fa-retweet tx-22"></i> */}
              <span className="menu-item-label">Referral Section</span>
            </a>{/* br-menu-link */}
          </li>{/* br-menu-item */}
          <li className="br-menu-item">
            <a href="Profile" className="br-menu-link ">
              <svg className="svg-inline--fa fa-user fa-w-14 menu-item-icon tx-22" aria-hidden="true" data-prefix="fas" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg>
                <path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z">
                </path>
              </svg>{/* <i class="menu-item-icon fas fa-user tx-22"></i> */}
              <span className="menu-item-label">User Profile</span>
            </a>{/* br-menu-link */}
          </li>{/* br-menu-item */}
          {/* br-menu-item */}
          {/* <li className="br-menu-item">
            <a href="?a=support" className="br-menu-link ">
              <svg className="svg-inline--fa fa-phone-square fa-w-14 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="phone-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg>
                <path fill="currentColor" d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM94 416c-7.033 0-13.057-4.873-14.616-11.627l-14.998-65a15 15 0 0 1 8.707-17.16l69.998-29.999a15 15 0 0 1 17.518 4.289l30.997 37.885c48.944-22.963 88.297-62.858 110.781-110.78l-37.886-30.997a15.001 15.001 0 0 1-4.289-17.518l30-69.998a15 15 0 0 1 17.16-8.707l65 14.998A14.997 14.997 0 0 1 384 126c0 160.292-129.945 290-290 290z">
                </path>
              </svg>
              <span className="menu-item-label">Contact Support</span>
            </a>
          </li> */}
          <li className="br-menu-item">
            <a onClick={logout} className="br-menu-link">
              <svg className="svg-inline--fa fa-sign-out fa-w-16 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="sign-out" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                <g>
                  <path fill="currentColor" d="M156.5,447.7l-12.6,29.5c-18.7-9.5-35.9-21.2-51.5-34.9l22.7-22.7C127.6,430.5,141.5,440,156.5,447.7z M40.6,272H8.5 c1.4,21.2,5.4,41.7,11.7,61.1L50,321.2C45.1,305.5,41.8,289,40.6,272z M40.6,240c1.4-18.8,5.2-37,11.1-54.1l-29.5-12.6 C14.7,194.3,10,216.7,8.5,240H40.6z M64.3,156.5c7.8-14.9,17.2-28.8,28.1-41.5L69.7,92.3c-13.7,15.6-25.5,32.8-34.9,51.5 L64.3,156.5z M397,419.6c-13.9,12-29.4,22.3-46.1,30.4l11.9,29.8c20.7-9.9,39.8-22.6,56.9-37.6L397,419.6z M115,92.4 c13.9-12,29.4-22.3,46.1-30.4l-11.9-29.8c-20.7,9.9-39.8,22.6-56.8,37.6L115,92.4z M447.7,355.5c-7.8,14.9-17.2,28.8-28.1,41.5 l22.7,22.7c13.7-15.6,25.5-32.9,34.9-51.5L447.7,355.5z M471.4,272c-1.4,18.8-5.2,37-11.1,54.1l29.5,12.6 c7.5-21.1,12.2-43.5,13.6-66.8H471.4z M321.2,462c-15.7,5-32.2,8.2-49.2,9.4v32.1c21.2-1.4,41.7-5.4,61.1-11.7L321.2,462z M240,471.4c-18.8-1.4-37-5.2-54.1-11.1l-12.6,29.5c21.1,7.5,43.5,12.2,66.8,13.6V471.4z M462,190.8c5,15.7,8.2,32.2,9.4,49.2h32.1 c-1.4-21.2-5.4-41.7-11.7-61.1L462,190.8z M92.4,397c-12-13.9-22.3-29.4-30.4-46.1l-29.8,11.9c9.9,20.7,22.6,39.8,37.6,56.9 L92.4,397z M272,40.6c18.8,1.4,36.9,5.2,54.1,11.1l12.6-29.5C317.7,14.7,295.3,10,272,8.5V40.6z M190.8,50 c15.7-5,32.2-8.2,49.2-9.4V8.5c-21.2,1.4-41.7,5.4-61.1,11.7L190.8,50z M442.3,92.3L419.6,115c12,13.9,22.3,29.4,30.5,46.1 l29.8-11.9C470,128.5,457.3,109.4,442.3,92.3z M397,92.4l22.7-22.7c-15.6-13.7-32.8-25.5-51.5-34.9l-12.6,29.5 C370.4,72.1,384.4,81.5,397,92.4z">
                  </path>
                  <circle fill="currentColor" cx={256} cy={364} r={28}>
                    <animate attributeType="XML" repeatCount="indefinite" dur="2s" attributeName="r" values="28;14;28;28;14;28;" />
                    <animate attributeType="XML" repeatCount="indefinite" dur="2s" attributeName="opacity" values="1;0;1;1;0;1;" />
                  </circle>
                  <path fill="currentColor" opacity={1} d="M263.7,312h-16c-6.6,0-12-5.4-12-12c0-71,77.4-63.9,77.4-107.8c0-20-17.8-40.2-57.4-40.2c-29.1,0-44.3,9.6-59.2,28.7 c-3.9,5-11.1,6-16.2,2.4l-13.1-9.2c-5.6-3.9-6.9-11.8-2.6-17.2c21.2-27.2,46.4-44.7,91.2-44.7c52.3,0,97.4,29.8,97.4,80.2 c0,67.6-77.4,63.5-77.4,107.8C275.7,306.6,270.3,312,263.7,312z">
                    <animate attributeType="XML" repeatCount="indefinite" dur="2s" attributeName="opacity" values="1;0;0;0;0;1;" />
                  </path>
                  <path fill="currentColor" opacity={0} d="M232.5,134.5l7,168c0.3,6.4,5.6,11.5,12,11.5h9c6.4,0,11.7-5.1,12-11.5l7-168c0.3-6.8-5.2-12.5-12-12.5h-23 C237.7,122,232.2,127.7,232.5,134.5z">
                    <animate attributeType="XML" repeatCount="indefinite" dur="2s" attributeName="opacity" values="0;0;1;1;0;0;" />
                  </path>
                </g>
              </svg>{/* <i class="menu-item-icon fa fa-sign-out tx-22"></i> */}
              <span className="menu-item-label">Sign Out</span>
            </a>{/* br-menu-link */}
          </li>{/* br-menu-item */}
        </ul>{/* br-sideleft-menu */}
        <label className="sidebar-label pd-x-10 mg-t-25 mg-b-20 tx-info">Information Summary</label>
        <div className="info-list">
          <div className="info-list-item">
            <div>
              <p className="info-list-label">Deposits</p>
              <h5 className="info-list-amount"><small>$</small>{userdetails.Totaldeposit}</h5>
            </div>
            <span className="peity-bar" data-peity="{ &quot;fill&quot;: [&quot;#336490&quot;], &quot;height&quot;: 35, &quot;width&quot;: 60 }" style={{ display: 'none' }}>8,6,5,9,8,4,9,3,5,9</span><svg className="peity" height={35} width={60}>
              <rect data-value={8} fill="#336490" x="1.3542500000000002" y="8.776388888888889" width="10.834" height="70.21111111111111" />
              <rect data-value={6} fill="#336490" x="14.896750000000003" y="26.329166666666666" width="10.833999999999998" height="52.65833333333333" />
              <rect data-value={5} fill="#336490" x="28.439250000000005" y="35.105555555555554" width="10.834" height="43.88194444444444" />
              <rect data-value={9} fill="#336490" x="41.981750000000005" y={0} width="10.833999999999996" height="78.9875">
              </rect>
              <rect data-value={8} fill="#336490" x="55.524249999999995" y="8.776388888888889" width="10.834000000000017" height="70.21111111111111" />
              <rect data-value={4} fill="#336490" x="69.06675" y="43.88194444444444" width="10.834000000000017" height="35.105555555555554" />
              <rect data-value={9} fill="#336490" x="82.60925" y={0} width="10.834000000000003" height="78.9875" />
              <rect data-value={3} fill="#336490" x="96.15175" y="52.65833333333333" width="10.834000000000003" height="26.329166666666666" />
              <rect data-value={5} fill="#336490" x="109.69425000000001" y="35.105555555555554" width="10.834000000000017" height="43.88194444444444" />
              <rect data-value={9} fill="#336490" x="123.23675" y={0} width="10.834000000000032" height="78.9875" />
            </svg>
          </div>{/* info-list-item */}
          <div className="info-list-item">
            <div>
              <p className="info-list-label">Account Balnce</p>
              <h5 className="info-list-amount"><small>$</small>{userdetails.balance}</h5>
            </div>
            <span className="peity-bar" data-peity="{ &quot;fill&quot;: [&quot;#1C7973&quot;], &quot;height&quot;: 35, &quot;width&quot;: 60 }" style={{ display: 'none' }}>4,3,5,7,12,10,4,5,11,7</span><svg className="peity" height={35} width={60}>
              <rect data-value={4} fill="#1C7973" x="1.33762" y="52.01666666666667" width="10.70096" height="26.008333333333333" />
              <rect data-value={3} fill="#1C7973" x="14.713820000000002" y="58.518750000000004" width="10.700959999999998" height="19.50625" />
              <rect data-value={5} fill="#1C7973" x="28.090020000000003" y="45.514583333333334" width="10.700959999999995" height="32.51041666666667" />
              <rect data-value={7} fill="#1C7973" x="41.46622000000001" y="32.510416666666664" width="10.700959999999988" height="45.51458333333334" />
              <rect data-value={12} fill="#1C7973" x="54.84241999999999" y={0} width="10.70096000000001" height="78.025">
              </rect>
              <rect data-value={10} fill="#1C7973" x="68.21862" y="13.004166666666663" width="10.70096000000001" height="65.02083333333334" />
              <rect data-value={4} fill="#1C7973" x="81.59482" y="52.01666666666667" width="10.70096000000001" height="26.008333333333333" />
              <rect data-value={5} fill="#1C7973" x="94.97102" y="45.514583333333334" width="10.70096000000001" height="32.51041666666667" />
              <rect data-value={11} fill="#1C7973" x="108.34722" y="6.502083333333331" width="10.70096000000001" height="71.52291666666667" />
              <rect data-value={7} fill="#1C7973" x="121.72341999999999" y="32.510416666666664" width="10.700959999999995" height="45.51458333333334" />
            </svg>
          </div>
          <div className="info-list-item">
            <div>
              <p className="info-list-label">Withdrawals</p>
              <h5 className="info-list-amount"><small>$</small>{userdetails.Totalwithdrawal}</h5>
            </div>
            <span className="peity-bar" data-peity="{ &quot;fill&quot;: [&quot;#8E4246&quot;], &quot;height&quot;: 35, &quot;width&quot;: 60 }" style={{ display: 'none' }}>1,2,1,3,2,10,4,12,7</span><svg className="peity" height={35} width={60}>
              <rect data-value={1} fill="#8E4246" x="1.2248666666666668" y="58.94166666666666" width="9.798933333333334" height="5.358333333333334" />
              <rect data-value={2} fill="#8E4246" x="13.473533333333334" y="53.58333333333333" width="9.798933333333329" height="10.716666666666669" />
              <rect data-value={1} fill="#8E4246" x="25.7222" y="58.94166666666666" width="9.79893333333333" height="5.358333333333334" />
              <rect data-value={3} fill="#8E4246" x="37.970866666666666" y="48.224999999999994" width="9.798933333333338" height="16.075000000000003" />
              <rect data-value={2} fill="#8E4246" x="50.219533333333324" y="53.58333333333333" width="9.798933333333345" height="10.716666666666669" />
              <rect data-value={10} fill="#8E4246" x="62.468199999999996" y="10.716666666666661" width="9.798933333333338" height="53.583333333333336" />
              <rect data-value={4} fill="#8E4246" x="74.71686666666666" y="42.86666666666667" width="9.798933333333338" height="21.43333333333333" />
              <rect data-value={12} fill="#8E4246" x="86.96553333333333" y={0} width="9.798933333333352" height="64.3">
              </rect>
              <rect data-value={7} fill="#8E4246" x="99.21419999999999" y="26.791666666666664" width="9.798933333333338" height="37.50833333333333" />
            </svg>
          </div>{/* info-list-item */}
          <div className="info-list-item">
            <div>
              <p className="info-list-label">Account Balance</p>
              <h5 className="info-list-amount"><small>$</small>0.00</h5>
            </div>
            <span className="peity-bar" data-peity="{ &quot;fill&quot;: [&quot;#9C7846&quot;], &quot;height&quot;: 35, &quot;width&quot;: 60 }" style={{ display: 'none' }}>3,12,7,9,2,3,4,5,2</span><svg className="peity" height={35} width={60}>
              <rect data-value={3} fill="#9C7846" x="0.9306944444444445" y="36.6375" width="7.445555555555556" height="12.212499999999999" />
              <rect data-value={12} fill="#9C7846" x="10.23763888888889" y={0} width="7.445555555555556" height="48.85">
              </rect>
              <rect data-value={7} fill="#9C7846" x="19.544583333333335" y="20.354166666666664" width="7.445555555555554" height="28.495833333333337" />
              <rect data-value={9} fill="#9C7846" x="28.851527777777775" y="12.212499999999999" width="7.445555555555558" height="36.6375" />
              <rect data-value={2} fill="#9C7846" x="38.15847222222222" y="40.708333333333336" width="7.445555555555558" height="8.141666666666666" />
              <rect data-value={3} fill="#9C7846" x="47.46541666666666" y="36.6375" width="7.445555555555565" height="12.212499999999999" />
              <rect data-value={4} fill="#9C7846" x="56.77236111111111" y="32.56666666666667" width="7.445555555555558" height="16.28333333333333" />
              <rect data-value={5} fill="#9C7846" x="66.07930555555555" y="28.495833333333334" width="7.445555555555558" height="20.354166666666668" />
              <rect data-value={2} fill="#9C7846" x="75.38625" y="40.708333333333336" width="7.445555555555558" height="8.141666666666666" />
            </svg>
          </div>{/* info-list-item */}
        </div>{/* info-list */}
        <br />
        <div className="ps__rail-x" style={{ left: '0px', top: '0px' }}>
          <div className="ps__thumb-x" tabIndex={0} style={{ left: '0px', width: '0px' }} />
        </div>
        <div className="ps__rail-y" style={{ top: '0px', height: '655px', right: '0px' }}>
          <div className="ps__thumb-y" tabIndex={0} style={{ top: '0px', height: '545px' }} />
        </div>
      </div>{/* br-sideleft */}
        </>
    )
}

export default Nav