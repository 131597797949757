import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyCyJabL7TPwYVvulufI1GkEM-A35Q-_7UQ",
  authDomain: "tintworldtrades.firebaseapp.com",
  databaseURL: "https://tintworldtrades-default-rtdb.firebaseio.com",
  projectId: "tintworldtrades",
  storageBucket: "tintworldtrades.appspot.com",
  messagingSenderId: "634603697937",
  appId: "1:634603697937:web:68045555dc6c25b1c67eb0"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()