import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
function BreakHeader() {
    const navigate = useNavigate();
    const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
    const [loading, setloading] = useState(false)
    const [step, setstep] = useState(1)

    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    navigate("/");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    setloading(false)
                } else {
                    console.log("No such document!");
                    setloading(false)
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };
    const logout = async () => {
        const let1 = await setloggedin(false);
        const let2 = await f.auth().signOut();
        const let3 = await navigate("/");
    };
    return (
        <div className="br-header">
            <div className="br-header-left">
                <div className="navicon-left hidden-md-down"><a id="btnLeftMenu" href="#"><svg className="svg-inline--fa fa-bars fa-w-14 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg>
                    <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
                    </path>
                </svg>{/* <i class="menu-item-icon fa fa-bars tx-22"></i> */}</a></div>
                <div className="navicon-left hidden-lg-up"><a id="btnLeftMenuMobile" href="#"><svg className="svg-inline--fa fa-bars fa-w-14 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg>
                    <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
                    </path>
                </svg>{/* <i class="menu-item-icon fa fa-bars tx-22"></i> */}</a></div>
                <div className="input-group hidden-xs-down wd-170 transition">
                    <input id="searchbox" type="text" className="form-control" placeholder="Search" />

                    <span className="input-group-btn">
                        <button className="btn btn-secondary" type="button"><svg className="svg-inline--fa fa-search fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                            <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z">
                            </path>
                        </svg>{/* <i class="fas fa-search"></i> */}</button>
                    </span>
                </div>{/* input-group */}
            </div>{/* br-header-left */}
            <div className="br-header-right">
                <nav className="nav">
                    <div className="dropdown">
                        <a href="#" className="nav-link pd-x-7 pos-relative" data-toggle="dropdown">
                            <div id="google_translate_element" style={{ width: 'auto', padding: 0 }} />
                        </a>
                    </div>{/* dropdown */}
                    <div className="dropdown">
                        <a href="#" className="nav-link pd-x-7 pos-relative" data-toggle="dropdown">
                            <svg className="svg-inline--fa fa-bell fa-w-14 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="bell" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg>
                                <path fill="currentColor" d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z">
                                </path>
                            </svg>{/* <i class="menu-item-icon fa fa-bell tx-22"></i> */}
                            {/* start: if statement */}
                            <span className="square-8 bg-danger pos-absolute t-15 r-5 rounded-circle" />
                            {/* end: if statement */}
                        </a>
                    </div>{/* dropdown */}
                    <div className="dropdown">
                        <a href="#" className="nav-link nav-link-profile" data-toggle="dropdown" aria-expanded="false">
                            <span className="logged-name hidden-md-down">{userdetails.fullname}</span>
                            <img src="https://img.icons8.com/?size=1x&id=11779&format=png" alt="Ayoricch" className="wd-32 rounded-circle" /> <span className="square-10 bg-success" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-header wd-250" x-placement="bottom-start" style={{ position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 48px, 0px)' }}>
                            <div className="tx-center">
                                <a href="#"><img alt="Ayoricch" src="https://img.icons8.com/?size=1x&id=11779&format=png" className="wd-80 rounded-circle" /> </a>
                                <h6 className="logged-fullname">{userdetails.fullname}</h6>
                                <p>{userdetails.email}</p>
                            </div>
                            <hr />
                            <div className="tx-center">
                                <span className="profile-earning-label">Overall Balance</span>
                                <h3 className="profile-earning-amount"><small>$</small>{userdetails.balance} <svg className="svg-inline--fa fa-arrow-up fa-w-14 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="arrow-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg>
                                    <path fill="currentColor" d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z">
                                    </path>
                                </svg>{/* <i class="menu-item-icon fa fa-arrow-up tx-22"></i> */}</h3>
                                <span className="profile-earning-text">profits + withdrawals</span>
                            </div>
                            <hr />
                            <ul className="list-unstyled user-profile-nav">
                                <li><a href="?a=edit_account"><svg className="svg-inline--fa fa-user-circle fa-w-16 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="user-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" data-fa-i2svg>
                                    <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z">
                                    </path>
                                </svg>{/* <i class="menu-item-icon fa fa-user-circle tx-22"></i> */} Edit Profile</a></li>
                                <li><a href="?a=deposit"><svg className="svg-inline--fa fa-plus-circle fa-w-16 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="plus-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                                    <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z">
                                    </path>
                                </svg>{/* <i class="menu-item-icon fa fa-plus-circle tx-22"></i> */} Deposit</a></li>
                                <li><a href="?a=withdraw"><svg className="svg-inline--fa fa-credit-card fa-w-18 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="credit-card" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg>
                                    <path fill="currentColor" d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128 0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576 80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z">
                                    </path>
                                </svg>{/* <i class="menu-item-icon fa fa-credit-card tx-22"></i> */} Withdrawal</a></li>
                                <li><a href="?a=logout"><svg className="svg-inline--fa fa-sign-out fa-w-16 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="sign-out" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                                    <g>
                                        <path fill="currentColor" d="M156.5,447.7l-12.6,29.5c-18.7-9.5-35.9-21.2-51.5-34.9l22.7-22.7C127.6,430.5,141.5,440,156.5,447.7z M40.6,272H8.5 c1.4,21.2,5.4,41.7,11.7,61.1L50,321.2C45.1,305.5,41.8,289,40.6,272z M40.6,240c1.4-18.8,5.2-37,11.1-54.1l-29.5-12.6 C14.7,194.3,10,216.7,8.5,240H40.6z M64.3,156.5c7.8-14.9,17.2-28.8,28.1-41.5L69.7,92.3c-13.7,15.6-25.5,32.8-34.9,51.5 L64.3,156.5z M397,419.6c-13.9,12-29.4,22.3-46.1,30.4l11.9,29.8c20.7-9.9,39.8-22.6,56.9-37.6L397,419.6z M115,92.4 c13.9-12,29.4-22.3,46.1-30.4l-11.9-29.8c-20.7,9.9-39.8,22.6-56.8,37.6L115,92.4z M447.7,355.5c-7.8,14.9-17.2,28.8-28.1,41.5 l22.7,22.7c13.7-15.6,25.5-32.9,34.9-51.5L447.7,355.5z M471.4,272c-1.4,18.8-5.2,37-11.1,54.1l29.5,12.6 c7.5-21.1,12.2-43.5,13.6-66.8H471.4z M321.2,462c-15.7,5-32.2,8.2-49.2,9.4v32.1c21.2-1.4,41.7-5.4,61.1-11.7L321.2,462z M240,471.4c-18.8-1.4-37-5.2-54.1-11.1l-12.6,29.5c21.1,7.5,43.5,12.2,66.8,13.6V471.4z M462,190.8c5,15.7,8.2,32.2,9.4,49.2h32.1 c-1.4-21.2-5.4-41.7-11.7-61.1L462,190.8z M92.4,397c-12-13.9-22.3-29.4-30.4-46.1l-29.8,11.9c9.9,20.7,22.6,39.8,37.6,56.9 L92.4,397z M272,40.6c18.8,1.4,36.9,5.2,54.1,11.1l12.6-29.5C317.7,14.7,295.3,10,272,8.5V40.6z M190.8,50 c15.7-5,32.2-8.2,49.2-9.4V8.5c-21.2,1.4-41.7,5.4-61.1,11.7L190.8,50z M442.3,92.3L419.6,115c12,13.9,22.3,29.4,30.5,46.1 l29.8-11.9C470,128.5,457.3,109.4,442.3,92.3z M397,92.4l22.7-22.7c-15.6-13.7-32.8-25.5-51.5-34.9l-12.6,29.5 C370.4,72.1,384.4,81.5,397,92.4z">
                                        </path>
                                        <circle fill="currentColor" cx={256} cy={364} r={28}>
                                            <animate attributeType="XML" repeatCount="indefinite" dur="2s" attributeName="r" values="28;14;28;28;14;28;" />
                                            <animate attributeType="XML" repeatCount="indefinite" dur="2s" attributeName="opacity" values="1;0;1;1;0;1;" />
                                        </circle>
                                        <path fill="currentColor" opacity={1} d="M263.7,312h-16c-6.6,0-12-5.4-12-12c0-71,77.4-63.9,77.4-107.8c0-20-17.8-40.2-57.4-40.2c-29.1,0-44.3,9.6-59.2,28.7 c-3.9,5-11.1,6-16.2,2.4l-13.1-9.2c-5.6-3.9-6.9-11.8-2.6-17.2c21.2-27.2,46.4-44.7,91.2-44.7c52.3,0,97.4,29.8,97.4,80.2 c0,67.6-77.4,63.5-77.4,107.8C275.7,306.6,270.3,312,263.7,312z">
                                            <animate attributeType="XML" repeatCount="indefinite" dur="2s" attributeName="opacity" values="1;0;0;0;0;1;" />
                                        </path>
                                        <path fill="currentColor" opacity={0} d="M232.5,134.5l7,168c0.3,6.4,5.6,11.5,12,11.5h9c6.4,0,11.7-5.1,12-11.5l7-168c0.3-6.8-5.2-12.5-12-12.5h-23 C237.7,122,232.2,127.7,232.5,134.5z">
                                            <animate attributeType="XML" repeatCount="indefinite" dur="2s" attributeName="opacity" values="0;0;1;1;0;0;" />
                                        </path>
                                    </g>
                                </svg>{/* <i class="menu-item-icon fa fa-sign-out tx-22"></i> */} Sign Out</a></li>
                            </ul>
                        </div>{/* dropdown-menu */}
                    </div>{/* dropdown */}
                </nav>
                <div className="navicon-right">
                    <a id="btnRightMenu" href="#" className="pos-relative">
                        <svg className="svg-inline--fa fa-bars fa-w-14 menu-item-icon tx-22" aria-hidden="true" data-prefix="fa" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg>
                            <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
                            </path>
                        </svg>{/* <i class="menu-item-icon fa fa-bars tx-22"></i> */}
                        {/* start: if statement */}
                        <span className="square-8 bg-danger pos-absolute t-10 r--5 rounded-circle" />
                        {/* end: if statement */}
                    </a>
                </div>{/* navicon-right */}
            </div>{/* br-header-right */}
        </div>
    )
}

export default BreakHeader